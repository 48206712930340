import React from "react";
import "./industries.css";
import { MdEmojiTransportation, MdOutlineCasino } from "react-icons/md";
import { IoGameControllerOutline } from "react-icons/io5";
import { GiBank } from "react-icons/gi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { SiYourtraveldottv } from "react-icons/si";
import { BsPeople, BsTranslate } from "react-icons/bs";

const Industries = () => {
  return (
    <>
      <div className="industries flex justify-around items-center flex-col">
        <div className="flex justify-center items-center flex-col">
          <h2 className="white-txt font-bold industries-we-serve-headline">
            Industries we serve
          </h2>
          <p className="white-txt industry-organizations-desc">
            We have a wealth of experience in serving a wide range of
            industries. Our team of expert software developers has a deep
            understanding of the unique needs and challenges of each industry,
            allowing us to deliver custom solutions that meet the specific
            requirements of our clients.
          </p>
        </div>
        <div className="icon-blocks-wrp-industries">
          <div className="industry-item-block" data-aos="fade-up">
            <div className="icon-wrp">
              <MdEmojiTransportation className="industry-icon" />
            </div>
            <h2 className="green-color-txt mt-4 green-text-selection">Traffic</h2>
          </div>

          <div
            className="industry-item-block"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon-wrp">
              <GiBank className="industry-icon" />
            </div>
            <h2 className="green-color-txt mt-4 green-text-selection">Finance</h2>
          </div>

          <div
            className="industry-item-block"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="icon-wrp">
              <IoGameControllerOutline className="industry-icon" />
            </div>
            <h2 className="green-color-txt mt-4 green-text-selection">Gaming</h2>
          </div>

          <div
            className="industry-item-block"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="icon-wrp">
              <AiOutlineShoppingCart className="industry-icon" />
            </div>
            <h2 className="green-color-txt mt-4 green-text-selection">Retail</h2>
          </div>
        </div>

        <div className="icon-blocks-wrp-industries">
          <div
            className="industry-item-block"
            data-aos="fade-up"
            data-aos-delay="0"
          >
            <div className="icon-wrp">
              <SiYourtraveldottv className="industry-icon" />
            </div>
            <h2 className="green-color-txt mt-4 green-text-selection">Travel</h2>
          </div>

          <div
            className="industry-item-block"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="icon-wrp">
              <BsTranslate className="industry-icon" />
            </div>
            <h2 className="green-color-txt mt-4 green-text-selection">Translation</h2>
          </div>

          <div
            className="industry-item-block"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="icon-wrp">
              <MdOutlineCasino className="industry-icon" />
            </div>
            <h2 className="green-color-txt mt-4 green-text-selection">Sports betting</h2>
          </div>

          <div
            className="industry-item-block"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div className="icon-wrp">
              <BsPeople className="industry-icon" />
            </div>
            <h2 className="green-color-txt mt-4 green-text-selection">Human Resources</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Industries;
