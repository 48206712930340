import { NavLink } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import LogoBlack from "../../../assets/images/logo.png";
import "./BurgerMenu.css";

const BurgerMenu = () => {
  const closeBurger = () => {
    const burgerMenu = document.getElementById("burger-menu");
    burgerMenu.classList.add("close-burger");
    burgerMenu.classList.replace("burger-visible", "hidden-burger");
    document.getElementById("header").style.zIndex = 9999;

    document.body.style.overflow = "auto";

    let timeout = setTimeout(() => {
      burgerMenu.classList.remove("close-burger");
      clearTimeout(timeout);
    }, 250);
  };

  return (
    <div className="burger-menu hidden-burger" id="burger-menu">
      <div className="burger-header flex justify-between items-center">
        <img src={LogoBlack} alt="Obelus doo" loading="lazy" decoding="async" />
        <IoClose
          className="burger-close"
          size={50}
          style={{ color: "#00141d" }}
          onClick={closeBurger}
        />
      </div>
      <div className="flex justify-center items-center w-[100%] py-[20px] bg-[#f5f6fa]">
        <h2 className="text-xl black-color-txt max-w-[80%] italic font-bold text-center">
          Solutions that work. Results that matter. Obelus.
        </h2>
      </div>
      <div className="burger-navlinks">
        <NavLink
          to="/"
          onClick={closeBurger}
          className={({ isActive }) =>
            isActive ? "burger-active" : "burger-unactive"
          }
          aria-label="Home burger page"
        >
          Home
        </NavLink>

        <NavLink
          to="/about-us"
          onClick={closeBurger}
          className={({ isActive }) =>
            isActive ? "burger-active" : "burger-unactive"
          }
          aria-label="About us burger page"
        >
          About
        </NavLink>

        <NavLink
          to="/services"
          onClick={closeBurger}
          className={({ isActive }) =>
            isActive ? "burger-active" : "burger-unactive"
          }
          aria-label="Services burger page"
        >
          Services
        </NavLink>

        <NavLink
          to="/case-studies"
          onClick={closeBurger}
          className={({ isActive }) =>
            isActive ? "burger-active" : "burger-unactive"
          }
          aria-label="Case studies burger page"
        >
          Case studies
        </NavLink>

        <NavLink
          to="/careers"
          onClick={closeBurger}
          className={({ isActive }) =>
            isActive ? "burger-active" : "burger-unactive"
          }
          aria-label="Careers burger page"
        >
          Careers
        </NavLink>

        <NavLink
          to="/contact-us"
          onClick={closeBurger}
          className={({ isActive }) =>
            isActive ? "burger-active" : "burger-unactive"
          }
          aria-label="Contact us burger page"
        >
          Contact
        </NavLink>
      </div>
    </div>
  );
};

export default BurgerMenu;
