import { useState } from "react";
import "./Job.css";
import { CgRadioChecked } from "react-icons/cg";
import { GiCheckMark } from "react-icons/gi";
import { BiRightArrowAlt } from "react-icons/bi";
import { ImLocation } from "react-icons/im";
import { useEffect } from "react";
import { slideDown, slideUp } from "../../../../../utils/animations";

const Job = ({ job, id, setPosition, setOpenMobile }) => {

  const MAX_CHAR_COUNT = window.innerWidth < 600 ? 150 : 300;

  const [showRequirements, setShowRequirements] = useState(false);
  const [showExpectations, setShowExpectations] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);
  const [showPreferredQualifications, setShowPreferredQualifications] =
    useState(false);

  const [isExpanded, setIsExpanded] = useState(job.description.length < MAX_CHAR_COUNT);

  const displayText = isExpanded
    ? job.description
    : job.description.slice(0, MAX_CHAR_COUNT) + "...";

  const isPreferredQualification =
    job.preferredQualifications !== undefined &&
    job.preferredQualifications.length > 0;

  useEffect(() => {
    if (showRequirements) {
      slideDown("requirements" + id);
      slideUp("expectations" + id);
      if (isPreferredQualification) slideUp("preferred-qualifications" + id);
      slideUp("benefits" + id);
    } else {
      slideUp("requirements" + id);
    }

    // eslint-disable-next-line
  }, [showRequirements, id]);

  useEffect(() => {
    if (showExpectations) {
      slideDown("expectations" + id);
      slideUp("requirements" + id);
      if (isPreferredQualification) slideUp("preferred-qualifications" + id);
      slideUp("benefits" + id);
    } else {
      slideUp("expectations" + id);
    }

    // eslint-disable-next-line
  }, [showExpectations, id]);

  useEffect(() => {
    if (showPreferredQualifications && isPreferredQualification) {
      slideDown("preferred-qualifications" + id);
      slideUp("requirements" + id);
      slideUp("expectations" + id);
      slideUp("benefits" + id);
    } else {
      if (isPreferredQualification) slideUp("preferred-qualifications" + id);
    }

    // eslint-disable-next-line
  }, [showPreferredQualifications, id]);

  useEffect(() => {
    if (showBenefits) {
      slideDown("benefits" + id);
      slideUp("requirements" + id);
      slideUp("expectations" + id);
      if (isPreferredQualification) slideUp("preferred-qualifications" + id);
    } else {
      slideUp("benefits" + id);
    }

    // eslint-disable-next-line
  }, [showBenefits, id]);

  const apply = () => {
    setPosition(job.position);

    if (window.innerWidth < 950) {
      setOpenMobile(true);
    } else {
      document
                  .getElementById("apply-form")
                  .scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <article className="w-[100%] flex justify-start items-start flex-col my-[40px]">
      <h2 className="position-title">{job.position}</h2>
      <div className="mb-[15px] flex justify-start items-center text-lg opacity-80">
        <ImLocation />
        <p className="ml-1">Niš or Remote</p>
      </div>
      <p className="black-color-txt my-[10px] text-justify">
        {displayText}{" "}
        {job.description.length > MAX_CHAR_COUNT && (
          <span
            onClick={() => setIsExpanded(!isExpanded)}
            className="cursor-pointer font-bold green-color-txt green-text-selection"
          >
            {isExpanded ? "Show less" : "Show more"}
          </span>
        )}
      </p>
      <div
        className="job-highlighted clickable-job"
        onClick={() => {
          const state = showRequirements === true ? false : true;
          setShowExpectations(false);
          setShowBenefits(false);
          setShowPreferredQualifications(false);
          setShowRequirements(state);
        }}
      >
        <div className="flex justify-start items-center">
          <CgRadioChecked />
          <p className="ml-2">You will stand out if you have</p>
        </div>
      </div>
      <div
        className="flex justify-start items-start flex-col ml-3  list-items-custom"
        id={"requirements" + id}
      >
        {job.requirements.map((requirement, index) => (
          <div
            className="flex justify-center items-center"
            key={index}
            style={index === 0 ? { marginTop: 10 } : {}}
          >
            <GiCheckMark className="green-color-txt mr-2 tick-icon" size={15} />
            <span>{requirement}</span>
          </div>
        ))}
      </div>
      <div
        className="job-highlighted mt-[10px] clickable-job"
        onClick={() => {
          const state = showExpectations === true ? false : true;
          setShowRequirements(false);
          setShowBenefits(false);
          setShowPreferredQualifications(false);
          setShowExpectations(state);
        }}
      >
        <div className="flex justify-start items-center">
          <CgRadioChecked />
          <p className="ml-2">What would be your job, you ask?</p>
        </div>
      </div>
      <div
        className="flex justify-start items-start flex-col ml-3  list-items-custom"
        id={"expectations" + id}
      >
        {job.expectations.map((expectations, index) => (
          <div
            className="flex justify-center items-center"
            key={index}
            style={index === 0 ? { marginTop: 10 } : {}}
          >
            <GiCheckMark className="green-color-txt mr-2 tick-icon" size={15} />
            <span>{expectations}</span>
          </div>
        ))}
      </div>
      {isPreferredQualification && (
        <>
          <div
            className="job-highlighted mt-[10px] clickable-job"
            onClick={() => {
              const state = showPreferredQualifications === true ? false : true;
              setShowExpectations(false);
              setShowBenefits(false);
              setShowRequirements(false);
              setShowPreferredQualifications(state);
            }}
          >
            <div className="flex justify-start items-center">
              <CgRadioChecked />
              <p className="ml-2">Preferred Qualifications</p>
            </div>
          </div>
          <div
            className="flex justify-start items-start flex-col ml-3  list-items-custom"
            id={"preferred-qualifications" + id}
          >
            {job.preferredQualifications.map((qualification, index) => (
              <div
                className="flex justify-center items-center"
                key={index}
                style={index === 0 ? { marginTop: 10 } : {}}
              >
                <GiCheckMark className="green-color-txt mr-2 tick-icon" size={15} />
                <span>{qualification}</span>
              </div>
            ))}
          </div>
        </>
      )}
      <div
        className="job-highlighted mt-[10px] clickable-job"
        onClick={() => {
          const state = showBenefits === true ? false : true;
          setShowRequirements(false);
          setShowExpectations(false);
          setShowPreferredQualifications(false);
          setShowBenefits(state);
        }}
      >
        <div className="flex justify-start items-center">
          <CgRadioChecked />
          <p className="ml-2">What you'll get?</p>
        </div>
      </div>
      <div
        className="flex justify-start items-start flex-col ml-3 mt-[10px] list-items-custom"
        id={"benefits" + id}
      >
        {job.benefits.map((benefits, index) => (
          <div
            className="flex justify-center items-center"
            key={index}
            style={index === 0 ? { marginTop: 10 } : {}}
          >
            <GiCheckMark className="green-color-txt mr-2 tick-icon" size={15} />
            <span>{benefits}</span>
          </div>
        ))}
      </div>
      <button
        className="Apply-now-button flex justify-center items-center"
        onClick={apply}
      >
        apply now <BiRightArrowAlt className="ml-2" size={20} />
      </button>
    </article>
  );
};

export default Job;
