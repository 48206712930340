export const ActiveJobsList = [
  {
    position: "Backend .NET Core Developer",
    description:
      "We are looking for a skilled Backend .NET Core Developer to join our growing team. We pride ourselves on providing innovative solutions to our clients, and are looking for someone who is passionate about technology and can help us continue to deliver exceptional results. As a Backend .NET Core Developer, you will be responsible for designing, developing and maintaining the backend of our web applications. You will be working closely with the development team, as well as our clients, to create scalable and efficient solutions that meet the needs of our users. The ideal candidate will have experience working with .NET Core and be able to demonstrate a strong understanding of software development best practices.",
    requirements: [
      "Proven experience working with .NET Core and related technologies such as ASP.NET Core, Entity Framework Core, etc.",
      "Strong knowledge of C#, SQL, and web technologies (HTML, CSS, JavaScript)",
      "Experience with Git or other version control systems",
      "Understanding of software development best practices and Agile methodologies",
      "Excellent problem-solving skills and ability to work in a fast-paced environment",
    ],
    expectations: [
      "Develop, implement, and maintain .NET Core web applications",
      "Collaborate with cross-functional teams to create scalable and efficient solutions",
      "Write clean, well-documented code that adheres to software development best practices",
      "Participate in code reviews and ensure the quality of the codebase",
      "Troubleshoot and debug issues as they arise",
      "Keep up-to-date with emerging trends and technologies in software development",
    ],
    benefits: [
      "Join the core team and work towards a lead position",
      "Work remotely or in our great office in Niš",
      "Competitive salary and benefits",
    ],
    applyText:
      "If you are passionate about technology and are looking for an exciting opportunity to work with a dynamic team, we encourage you to apply for this role. We offer competitive compensation packages, opportunities for growth and development, and a fun and collaborative work environment.",
  },
  {
    position: "Node.js Developer",
    description:
      "We are looking for a skilled Node.js Developer to join our growing team. We pride ourselves on providing innovative solutions to our clients, and are looking for someone who is passionate about technology and can help us continue to deliver exceptional results. As a Node.js Developer, you will be responsible for designing, developing and maintaining web applications using Node.js. You will be working closely with the development team, as well as our clients, to create scalable and efficient solutions that meet the needs of our users. The ideal candidate will have experience working with Node.js and be able to demonstrate a strong understanding of software development best practices.",
    requirements: [
      "Proven experience working with Node.js and related technologies such as Express.js, MongoDB, etc.",
      "Strong knowledge of JavaScript and web technologies (HTML, CSS, JavaScript)",
      "Experience with Git or other version control systems",
      "Understanding of software development best practices and Agile methodologies",
      "Excellent problem-solving skills and ability to work in a fast-paced environment",
    ],
    expectations: [
      "Develop, implement, and maintain Node.js web applications",
      "Collaborate with cross-functional teams to create scalable and efficient solutions",
      "Write clean, well-documented code that adheres to software development best practices",
      "Participate in code reviews and ensure the quality of the codebase",
      "Troubleshoot and debug issues as they arise",
      "Keep up-to-date with emerging trends and technologies in software development",
    ],
    benefits: [
      "Join the core team and work towards a lead position",
      "Work remotely or in our great office in Niš",
      "Competitive salary and benefits",
    ],
    applyText:
      "If you are passionate about technology and are looking for an exciting opportunity to work with a dynamic team, we encourage you to apply for this role. We offer competitive compensation packages, opportunities for growth and development, and a fun and collaborative work environment.",
  },
  {
    position: "React.js Developer",
    description:
      "Obelus is looking for a skilled React.js Developer to join our team of talented developers. We are passionate about creating innovative solutions for our clients, and we are looking for someone who shares our passion for technology and can help us continue to deliver exceptional results. As a React.js Developer, you will be responsible for designing, developing, and maintaining web applications using React.js. You will work closely with our development team, as well as our clients, to create scalable and efficient solutions that meet the needs of our users. The ideal candidate should have a deep understanding of React.js and its related technologies, as well as the ability to demonstrate software development best practices.",
    requirements: [
      "Proven experience working with React.js and related technologies such as Redux, React Native, etc.",
      "Strong knowledge of JavaScript and web technologies (HTML, CSS, JavaScript)",
      "Experience with Git or other version control systems",
      "Understanding of software development best practices and Agile methodologies",
      "Familiarity with RESTful APIs and GraphQL",
      "Strong problem-solving skills and ability to work in a fast-paced environment",
    ],
    expectations: [
      "Design, develop, and maintain React.js web applications",
      "Collaborate with cross-functional teams to create scalable and efficient solutions",
      "Write clean, maintainable, and well-documented code that adheres to software development best practices",
      "Participate in code reviews and ensure the quality of the codebase",
      "Debug and troubleshoot issues as they arise",
      "Keep up-to-date with emerging trends and technologies in software development",
    ],
    benefits: [
      "Join the core team and work towards a lead position",
      "Work remotely or in our great office in Niš",
      "Competitive salary and benefits",
    ],
    applyText:
      "If you are passionate about technology and are looking for an exciting opportunity to work with a dynamic team, we encourage you to apply for this role. We offer competitive compensation packages, opportunities for growth and development, and a fun and collaborative work environment.",
  },
  {
    position: "Full-stack .NET Developer",
    description:
      "Obelus is looking for a skilled Full-stack .NET Developer to join our team of talented developers. We are passionate about creating innovative solutions for our clients, and we are looking for someone who shares our passion for technology and can help us continue to deliver exceptional results. As a Full-stack .NET Developer, you will be responsible for designing, developing, and maintaining web applications using the .NET framework. You will work closely with our development team, as well as our clients, to create scalable and efficient solutions that meet the needs of our users. The ideal candidate should have a deep understanding of both front-end and back-end technologies and be able to demonstrate software development best practices.",
    requirements: [
      "Proven experience working with the .NET framework and related technologies such as C#, ASP.NET, etc.",
      "Strong knowledge of front-end web technologies (HTML, CSS, JavaScript)",
      "Experience with Git or other version control systems",
      "Understanding of software development best practices and Agile methodologies",
      "Familiarity with SQL Server and other database technologies",
      "Strong problem-solving skills and ability to work in a fast-paced environment",
    ],
    expectations: [
      "Design, develop, and maintain .NET web applications",
      "Collaborate with cross-functional teams to create scalable and efficient solutions",
      "Write clean, maintainable, and well-documented code that adheres to software development best practices",
      "Participate in code reviews and ensure the quality of the codebase",
      "Debug and troubleshoot issues as they arise",
      "Keep up-to-date with emerging trends and technologies in software development",
    ],
    preferredQualifications: [
      "Experience with modern front-end frameworks such as React, Angular, or Vue.js",
      "Experience with cloud platforms such as Azure or AWS",
      "Familiarity with microservices architecture and containerization technologies like Docker",
    ],
    benefits: [
      "Join the core team and work towards a lead position",
      "Work remotely or in our great office in Niš",
      "Competitive salary and benefits",
    ],
    applyText:
      "If you are passionate about technology and are looking for an exciting opportunity to work with a dynamic team, we encourage you to apply for this role. We offer competitive compensation packages, opportunities for growth and development, and a fun and collaborative work environment.",
  },
  {
    position: "Developer Intern",
    description:
      "Obelus is looking for a Developer Intern to join our team of talented developers. We are passionate about creating innovative solutions for our clients, and we are looking for someone who shares our passion for technology and can help us continue to deliver exceptional results. As a Developer Intern, you will have the opportunity to work alongside our team of experienced developers and gain practical, hands-on experience in the field of software development. You will work on real-world projects, participate in team meetings, and receive guidance and mentorship from our senior developers. The ideal candidate should have a strong interest in software development and be willing to learn and grow in a fast-paced environment.",
    requirements: [
      "Strong knowledge of programming fundamentals and data structures",
      "Familiarity with at least one programming language such as .NET, .NET Core or Node.js",
      "Strong problem-solving skills and attention to detail",
      "Ability to work independently and as part of a team",
      "Excellent communication skills and ability to learn quickly",
    ],
    expectations: [
      "Assist with the design, development, and testing of software applications",
      "Participate in team meetings and provide updates on project progress",
      "Collaborate with team members to create efficient and scalable solutions",
      "Write clean, maintainable, and well-documented code",
      "Debug and troubleshoot issues as they arise",
      "Keep up-to-date with emerging trends and technologies in software development",
    ],
    preferredQualifications: [
      "Familiarity with Git or other version control systems",
      "Understanding of software development best practices and Agile methodologies",
      "Experience with web technologies such as HTML, CSS, and JavaScript",
      "Familiarity with databases and SQL",
    ],
    benefits: [
      "Join the core team and work towards a lead position",
      "Work remotely or in our great office in Niš",
      "Competitive salary and benefits",
    ],
    applyText:
      "If you are passionate about technology and are looking for an exciting opportunity to gain practical, hands-on experience in software development, we encourage you to apply for this internship. We offer a supportive and collaborative work environment, opportunities for growth and development, and the chance to work on real-world projects alongside our team of experienced developers.",
  },
];
