import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/booking-3.json";
import animation1 from "../../assets/animations/booking-2.json";
import animation2 from "../../assets/animations/booking.json";
import animation3 from "../../assets/animations/booking-1.json";
import AnimatedView from "../../utils/AnimatedView";
import MetaTags from "../../components/Layout/MetaTags";
import MetaTagasData from "../../data/meta-tags";
import Header from "../../components/Layout/Header/Header";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";

const BookingApp = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <MetaTags data={MetaTagasData.BookingApp} />
      <Header isInverted={true} />
      <div className="flex justify-start items-start flex-col w-[100%]">
        <div className="case-study-banner">
          <div className="data-wrapper-case-studie">
            <div className="casestudies-banner-animation-formated">
              <AnimatedView animation={animation} isLazyLoading={false} />
            </div>
            <div className="casestudies-txt-studie flex justify-center items-center flex-col">
              <h1 className="headline-for-case-study">Booking app</h1>
              <p className="case-study-desc">
                At Obelus, we specialize in creating innovative software
                solutions for various industries. One of our areas of expertise
                is developing parking management systems. We are pleased to
                offer our solution for an online booking parking log that can be
                accessed through a mobile app.
              </p>
              <div className="flex justify-center items-center industry-tag-case-study">
                <span>#</span>
                <p>Traffic Industry</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-1">
          <div className="data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">About project</p>
              <p className="normal-text text-space">
                Our online booking parking log is designed to streamline the
                parking process and make it easy and convenient for users to
                reserve and pay for parking spaces. The app allows users to view
                available parking spaces, select a desired space, and book it in
                real-time. The payment process can be done quickly and securely
                using credit cards or other payment methods.
              </p>
            </div>
            <div className="data-picture-2">
              <AnimatedView animation={animation1} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-2">
          <div className="data-wrp remove-margs-data-wrp">
            <div className="data-picture-2">
              <AnimatedView animation={animation2} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="normal-text text-space">
                Our mobile app is user-friendly and features an intuitive design
                that makes it easy for users to navigate and find the
                information they need. The app is also highly customizable,
                allowing you to tailor it to your specific needs and
                requirements. We understand the importance of data security and
                privacy, and our app is built to meet the highest security
                standards, ensuring that user data is protected at all times.
              </p>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-3">
          <div className="data-wrp remove-margs-data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="normal-text text-space">
                We are confident that our online booking parking log will
                provide a seamless experience for your customers and help you to
                optimize your parking management processes. If you have any
                questions or would like to learn more about our solution, please
                don't hesitate to contact us. We would be happy to schedule a
                call with you to discuss your needs and provide a detailed
                proposal.
              </p>
            </div>
            <div className="data-picture-2">
              <AnimatedView animation={animation3} />
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default BookingApp;
