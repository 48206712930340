import React from "react";
import "./LifeAtObelus.css";
import { NavLink } from "react-router-dom";
import ObelusLogo from "../../../assets/images/obelus-symbol.png";
import img1 from "../../../assets/images/obelus-doo-open-positions.png";
import img2 from "../../../assets/images/obelus-doo-careers.png";
//import { lifeAtObelusData } from "../../../data/life-at-obelus";

const LifeAtObelus = () => {
  return (
    <div
      className="w-[100%] flex justify-center items-center flex-col py-12"
      id="life-at-obelus"
    >
      <div className="flex justify-around items-center job-introudction-wrp">
        <div className="flex justify-center items-start">
          <div className="job-introudction flex justify-start items-start flex-col">
            <h2 className="headline-in-job-introductions">
              We believe that our employees are our greatest asset
            </h2>
            <p className="job-introductions-text">
              Our company culture is built on a foundation of transparency,
              collaboration, and innovation. We foster an open-door policy and
              encourage all team members to share their ideas and insights
              freely, so that we can work together to create innovative
              solutions that meet the needs of our clients.
            </p>
            <NavLink
              to="/contact-us"
              className="lets-connect-btn flex justify-around items-center"
              aria-label="Contact us - life at obelus page"
            >
              <img
                src={ObelusLogo}
                width={42}
                height={40}
                alt="Obelus doo symbol"
                className="obelus-symbol"
                loading="lazy"
                decoding="async"
              />
              Let's Connect
            </NavLink>
          </div>
        </div>
        <img
          src={img1}
          alt="Obelus doo Careers"
          className="job-introduction-img"
          loading="lazy"
          decoding="async"
        />
      </div>
      <div className="life-at-obelus">
        <div className="flex justify-center items-center flex-col life-at-obelus-wrapper">
          <h2 className="headline-in-job-introductions">Life at Obelus</h2>
          <p
            className="job-introductions-text text-center mt-2"
            style={{ textAlign: "center" }}
          >
            We believe in work-life balance and understand that our team members
            have lives outside of work. To support this, we offer flexible
            working hours and remote work options, so that our employees can
            achieve a healthy balance between their personal and professional
            lives.
          </p>
           {/*<div className="benefit-items-wrapper">
             {lifeAtObelusData.map((data, index) => (
              <div
                className="benefit-item justify-start items-start flex-col"
                data-aos={data.animation}
                key={index}
              >
                <div className="icons">
                  <div className="first-icon">{data.firstIcon}</div>
                  <div className="second-icon">{data.secondIcon}</div>
                </div>
                <h2 className="benefit-title">{data.title}</h2>
                <p className="benefit-desc">{data.description}</p>
              </div>
            ))} 
          </div> */}
        </div>
      </div>
      <div className="flex justify-around items-center job-introudction-wrp">
        <div className="flex justify-around items-start">
          <div className="flex justify-start items-start flex-col">
            <h2 className="headline-in-job-introductions">
              Do you have your dream job?
            </h2>
            <p className="job-introductions-text">
              Overall, life at Obelus is dynamic, challenging, and rewarding. We
              are passionate about what we do, and we are dedicated to helping
              our employees achieve their full potential both personally and
              professionally. If you are interested in joining our team, we
              encourage you to explore our current career opportunities and
              submit your application today.
            </p>
          </div>
        </div>
        <img
          src={img2}
          alt="Obelus doo Dream job"
          className="job-introduction-img job-introduction-img-2"
          loading="lazy"
          decoding="async"
        />
      </div>
    </div>
  );
};

export default LifeAtObelus;
