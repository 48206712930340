const useDevice = () => {
  //MOBILE 0, TABLET 1, LAPTOP 2, DESKTOP 3
  let device = 0;
  let screen = window.innerWidth;

  if (screen <= 500) {
    device = 0;
  } else if (screen > 500 && screen <= 992) {
    device = 1;
  } else if (screen > 992 && screen <= 1100) {
    device = 2;
  } else {
    device = 3;
  }

  return device;
};

export default useDevice;
