import axios from "axios";
import vars from "../data/vars";

class ApiService {
  static async Newsletter(email) {
    try {
      let { data } = await axios.post(`${vars.server}/newsletter/subscribe`, {
        email,
      });

      return { ok: true, message: data.message };
    } catch (err) {
      return this.apiError(err);
    }
  }

  static async Contact(name, email, title, message) {
    try {
      let { data } = await axios.post(
        `${vars.server}/contact-us/ask-question`,
        { name, email, title, message }
      );

      return { ok: true, message: data.message };
    } catch (err) {
      return this.apiError(err);
    }
  }

  static async applyForJob(
    firstName,
    lastName,
    email,
    phone,
    position,
    linkedinOrWebsite,
    financialExpectation,
    additionalInformation,
    file
  ) {
    try {
      let { data } = await axios.post(`${vars.server}/job-applications/apply`, {
        firstName,
        lastName,
        email,
        phone,
        position,
        linkedinOrWebsite,
        financialExpectation,
        additionalInformation,
      });

      if (data.applicationId) {
        let res = await this.uploadFile(file, data.applicationId);
        if (!res.ok) return { ok: false, message: res.message };
      }

      return {
        ok: true,
        applicationId: data.applicationId,
        message: data.message,
      };
    } catch (err) {
      return this.apiError(err);
    }
  }

  static async uploadFile(file, appId) {
    try {
      let formData = new FormData();
      formData.append("file", file);

      let { data } = await axios.post(
        `${vars.server}/job-applications/upload-resume?applicationId=${appId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return { ok: true, message: data.message };
    } catch (err) {
      return this.apiError(err);
    }
  }

  static apiError(err) {
    let errorMsg = err?.response?.data;
    if (
      errorMsg !== undefined &&
      errorMsg !== null &&
      errorMsg.message !== undefined
    ) {
      return { ok: false, message: errorMsg.message };
    }
    return { ok: false, message: "Internal error" };
  }
}

export default ApiService;
