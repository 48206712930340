import React from "react";
import "./JobsBanner.css";
import animation from "../../../assets/animations/careers.json";
import { NavLink } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import AnimatedView from "../../../utils/AnimatedView";

const JobsBanner = () => {
  return (
    <div className="jobs-banner">
      <div className="jobs-banner-data">
        <div className="jobs-banner-animation">
          <AnimatedView animation={animation} isLazyLoading={false} />
        </div>
        <div className="jobs-banner-text">
          <h1 className="fadeIn-jobs green-text-selection">Join the family</h1>
          <p className="fadeIn-jobs">
            We don't just see our team as employees, we see them as members of
            our family. We believe in fostering a positive, collaborative and
            supportive environment where everyone can thrive and reach their
            full potential.
          </p>
          <div className="realted-links">
            <NavLink
              to="/careers/life-at-obelus"
              aria-label="Life at obelus page"
            >
              Life at Obelus <FaArrowRight className="job-arrow" />{" "}
            </NavLink>
            <NavLink to="/careers/internship" aria-label="Intership page">
              Internship <FaArrowRight className="job-arrow" />
            </NavLink>
            <NavLink
              to="/careers/open-positions"
              aria-label="Open positions page"
            >
              Open positions <FaArrowRight className="job-arrow" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsBanner;
