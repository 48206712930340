import React, { useRef, useState } from "react";
import "./Contact.css";
import {
  AiOutlineMail,
  AiOutlineInstagram,
  AiFillLinkedin,
  AiOutlineArrowRight,
  AiOutlineLinkedin,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { TbSend } from "react-icons/tb";
import vars from "../../../data/vars";
import ApiService from "../../../services/api.service";
import {
  enableDefaultButton,
  disableDefaultButton,
  showMessage,
} from "../../../utils/animations";

const Contact = () => {
  const [isEmailRequestSent, setIsEmailRequestSent] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  const titleRef = useRef();
  const messageRef = useRef();
  const resMessageRef = useRef();
  const submitButtonRef = useRef();

  const emailUs = () => {
    document.getElementById("email-us").scrollIntoView({ behavior: "smooth" });
  };

  const showError = (msg) => {
    showMessage(resMessageRef, msg, true);
    enableDefaultButton(submitButtonRef);
    setIsEmailRequestSent(false);
  };

  const contact = async () => {
    setIsEmailRequestSent(true);

    let name = nameRef.current.value.trim();
    let email = emailRef.current.value.trim();
    let title = titleRef.current.value.trim();
    let message = messageRef.current.value.trim();

    disableDefaultButton(submitButtonRef);

    if (name.length < 1) {
      showError("Name cannot be empty.");
      return;
    }

    if (!email.match(vars.emailRegex)) {
      showError("Please, enter the valid email address.");
      return;
    }

    if (title.length < 1) {
      showError("Title cannot be empty.");
      return;
    }

    if (message.trim().length < 1) {
      showError("Message cannot be empty.");
      return;
    }

    if (message.trim().length > 2000) {
      showError("Message can be 2000 characters long.");
      return;
    }

    if (email.trim().length > 50) {
      showError("Email 50 characters long.");
      return;
    }

    if (name.trim().length > 50) {
      showError("Name 50 characters long.");
      return;
    }

    if (title.trim().length > 50) {
      showError("Title 50 characters long.");
      return;
    }

    let res = await ApiService.Contact(name, email, title, message);

    if (res.ok) {
      nameRef.current.value = "";
      emailRef.current.value = "";
      titleRef.current.value = "";
      messageRef.current.value = "";

      setIsEmailRequestSent(false);
    } else {
      setIsEmailRequestSent(false);
    }

    enableDefaultButton(submitButtonRef);

    showMessage(resMessageRef, res.message, !res.ok);
  };

  return (
    <div
      className="w-[100%] flex justify-center items-center flex-col py-12"
      id="contact-us"
    >
      <h2 className="lets-connect-headline">Let's Connect</h2>
      <div className="text-center connect-description">
        <p>
          We value communication and transparency. If you have any questions or
          comments, or if you would like to learn more about our products and
          services, please don't hesitate to contact us.
        </p>
      </div>
      <div className="w-[100%] flex justify-center items-start contact-blocks-wrapper">
        <div className="contact-block" data-aos="fade-up" onClick={emailUs}>
          <div className="email-block-icon">
            <AiOutlineMail size={45} />
          </div>
          <div className="flex justify-around items-start flex-col">
            <p className="contact-block-headline">Email us</p>
            <p className="contact-block-description">
              Ask a question by email and we will respond within a few hours.
            </p>
            <div className="connect-button">
              <p>Leave a message</p>
              <AiOutlineArrowRight className="connect-button-icon" />
            </div>
          </div>
        </div>
        <div
          className="contact-block"
          data-aos="fade-up"
          onClick={() => {
            window.open(
              "https://www.linkedin.com/company/obelusdoo/",
              "_blank"
            );
          }}
        >
          <div className="email-block-icon">
            <AiOutlineLinkedin size={45} />
          </div>
          <div className="flex justify-around items-start flex-col">
            <p className="contact-block-headline">Connect with us</p>
            <p className="contact-block-description">
              You can meet us at our office and discuss the details of your
              question.
            </p>
            <div className="connect-button">
              <p>Our linkedin</p>
              <AiOutlineArrowRight className="connect-button-icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] flex justify-center items-start flex-row contact-content-wrapper">
        <div className="flex justify-start items-start flex-col contact-form-margins">
          <div
            className="flex justify-start items-start flex-col w-[100%] contact-wrapper"
            id="email-us"
          >
            <h2 className="green-color-txt contact-headline green-text-selection">
              Leave a message
            </h2>
            <p className="txt-color contact-desc">
              Fill out the enquiry form and we will get back to you as soon as
              possible.
            </p>
          </div>
          <div className="flex justify-between items-start w-[100%] contact-name-and-email-wrapper">
            <input
              ref={nameRef}
              type="text"
              className="contact-name contact-field"
              placeholder="Your Name"
              autoComplete="off"
            />
            <input
              ref={emailRef}
              type="text"
              className="contact-email  contact-field"
              placeholder="Email Address"
              autoComplete="off"
            />
          </div>
          <input
            ref={titleRef}
            type="text"
            className="contact-subject  contact-field"
            placeholder="Title"
            autoComplete="off"
          />
          <textarea
            ref={messageRef}
            name="message"
            id=""
            cols="30"
            rows="10"
            className="contact-message  contact-field"
            placeholder="How we can help?"
          ></textarea>
          <span
            ref={resMessageRef}
            className="contact-res-message mt-[5px]"
          ></span>
          <button
            ref={submitButtonRef}
            className="send-btn flex justify-center items-center"
            onClick={contact}
          >
            {isEmailRequestSent ? (
              <AiOutlineLoading3Quarters className="loading-spinner" />
            ) : (
              <>
                Submit <TbSend className="ml-2" size={35} />
              </>
            )}
          </button>
        </div>
        <div className="other-contact flex justify-start items-start flex-col">
          <div className="contact-other-wrp flex justify-start items-start flex-col">
            <p className="txt-color text-3xl contact-other-headline mb-10">
              Obelus doo
            </p>
            <div className=" flex justify-start items-start">
              <MdLocationPin size={30} color="#1cdb86" />
              <p className="other-info txt-color">
                <a
                  href="https://goo.gl/maps/Wih8RPxVrQy1MtSz5"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Obelus doo headquarters"
                >
                  Vardarska 15 <br /> 18000 Niš, Serbia
                </a>
              </p>
            </div>
            <div className=" flex justify-start items-center mt-7">
              <AiOutlineMail size={30} color="#1cdb86" />
              <p className="other-info txt-color">
                <a
                  href="mailto:info@obelus.rs"
                  aria-label="Obelus doo Contact email"
                >
                  info@obelus.rs
                </a>
              </p>
            </div>
            <div className=" flex justify-start items-center mt-7">
              <BiPhone size={30} color="#1cdb86" />
              <p className="other-info txt-color">
                <a
                  href="tel:+381677500005"
                  aria-label="Obelus doo Contact phone"
                >
                  +381 677 500005
                </a>
              </p>
            </div>
          </div>
          <div className="social-media flex justify-center items-center">
            <div className="flex justify-around w-[70%] items-center">
              <a
                href="https://www.instagram.com/obelus_doo/"
                target="_blank"
                rel="noreferrer"
                className="social-media-block"
                aria-label="Obelus doo instagram"
              >
                <AiOutlineInstagram size={40} />
              </a>
              <a
                href="https://www.facebook.com/obelus.doo/"
                target="_blank"
                rel="noreferrer"
                className="social-media-block"
                aria-label="Obelus doo Facebook"
              >
                <FaFacebook size={40} />
              </a>

              <a
                href="https://www.linkedin.com/company/obelusdoo/"
                target="_blank"
                rel="noreferrer"
                className="social-media-block"
                aria-label="Obelus doo Linkedin"
              >
                <AiFillLinkedin size={40} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
