import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/travel.json";
import animation1 from "../../assets/animations/travel-1.json";
import animation2 from "../../assets/animations/travel-2.json";
import AnimatedView from "../../utils/AnimatedView";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";
import Header from "../../components/Layout/Header/Header";
import MetaTags from "../../components/Layout/MetaTags";
import MetaTagasData from "../../data/meta-tags";

const SocialFunctionalities = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <MetaTags data={MetaTagasData.SocialFunctionalitiesForTravelers} />
      <Header isInverted={true} />
      <div className="flex justify-start items-start flex-col w-[100%]">
        <div className="case-study-banner">
          <div className="data-wrapper-case-studie">
            <div className="casestudies-banner-animation-formated">
              <AnimatedView animation={animation} isLazyLoading={false} />
            </div>
            <div className="casestudies-txt-studie flex justify-center items-center flex-col">
              <h1 className="headline-for-case-study">
                Social functionalities for passengers
              </h1>
              <p className="case-study-desc">
                At Obelus, our team of expert software developers has experience
                working on social functionalities for passengers traveling to
                the same destinations.
              </p>
              <div className="flex justify-center items-center industry-tag-case-study">
                <span>#</span>
                <p>Travel industry</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-1">
          <div className="data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">About project</p>
              <p className="normal-text text-space">
                We understand that traveling can be an isolating experience, and
                we have developed a range of services designed to make it easier
                for travelers to meet and socialize with other users traveling
                to the same destination.{" "}
              </p>
              <p className="normal-text text-space">
                One of the services we have developed is a public profile
                service that allows users to create a profile that can be seen
                by other users. This service enables users to introduce
                themselves to other travelers and connect with people who share
                similar interests.
              </p>
              <p className="normal-text text-space">
                We have also developed an event/gathering service that allows
                users to create and join events in the city where they are
                staying. This service enables users to organize social events
                and activities and connect with other travelers who are in the
                same city at the same time.
              </p>
            </div>
            <div className="data-picture">
              <AnimatedView animation={animation1} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-2">
          <div className="data-wrp text-part-2-responsive">
            <div className="data-picture">
              <AnimatedView animation={animation2} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="normal-text text-space">
                To facilitate communication and socialization, we have developed
                a chat service that can be one-on-one or group-based. Users are
                automatically added to city-level group chats when they book
                accommodation, and they can also create their own private group
                chats to connect with other travelers who share their interests.
              </p>
              <p className="normal-text text-space">
                Our chat service supports a range of media, including text
                messages, images, video, and audio files, which enables users to
                share their travel experiences in real-time with other
                travelers.
              </p>
              <p className="normal-text text-space">
                Overall, our social functionalities are designed to enhance the
                travel experience by facilitating socialization and connection
                among users who are traveling to the same destination.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default SocialFunctionalities;
