import React from "react";
import About from "../components/Home/About/About";
import Header from "../components/Layout/Header/Header";
import SiteMap from "../components/Layout/SiteMap/SiteMap";
import Contact from "../components/Home/Contact/Contact";
import Industries from "../components/Home/Industries/Industries";
import Services from "../components/Home/Services/Services";
import Newsletter from "../components/Layout/Newsletter/Newsletter";
import JobOpenings from "../components/Home/JobOpenings/JobOpenings";
import ScrollToTopButton from "../components/Layout/ScrollToTopButton";
import MetaTags from "../components/Layout/MetaTags";

import MetaTagasData from "../data/meta-tags";
import { useScroll } from "../hooks/useScroll";
import AnimatedBanner from "../components/Home/AnimatedBanner/AnimatedBanner";

const Home = () => {
  const metaData = MetaTagasData.Home;
  useScroll(metaData);

  return (
    <>
      <MetaTags data={metaData} />
      <Header />
      <AnimatedBanner />
      <About />
      <Services />
      <Industries />
      <JobOpenings />
      <Contact />
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default Home;
