import React from "react";
import "./internship.css";
import animation from "../../../assets/animations/developer.json";
import AnimatedView from "../../../utils/AnimatedView";

export const Internship = ({ setPosition, setOpenMobile }) => {
  return (
    <div id="internship">
      <div className="internship-backdrop">
        <div className="internship-text">
          <h2 className="internship-title">Internship</h2>
          <p className="internship-txt">
            We offer exciting internship opportunities to students and recent
            graduates who are passionate about software development and looking
            to gain real-world experience in the industry. Our internship
            program is designed to provide participants with hands-on experience
            in all aspects of software development, from discovery and design to
            development and support.
          </p>
          <p className="internship-txt">
            As an intern at Obelus, you will have the opportunity to work on
            real projects alongside our team of experienced software developers.
            You will gain valuable experience in a wide range of technologies
            and programming languages, and learn from industry experts who are
            committed to helping you develop your skills and advance your
            career.
          </p>
          <p className="internship-txt">
            Our internship program is designed to be flexible, with
            opportunities for full-time and part-time positions, as well as
            remote work options. We provide our interns with competitive
            compensation, as well as opportunities for professional development
            and networking.
          </p>
          <button
            className="Apply-internship"
            onClick={() => {
              setPosition("Developer Intern");
              if (window.innerWidth < 950) {
                setOpenMobile(true);
              } else {
                document
                  .getElementById("apply-form")
                  .scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            APPLY HERE
          </button>
        </div>
        <div className="internship-animation">
          <AnimatedView animation={animation} />
        </div>
      </div>
    </div>
  );
};
