import React from "react";
import "./ApplyForm.css";
import FormConent from "../FormConent";

const ApplyForm = ({ setPosition, position }) => {
  return (
    <div
      id="apply-form"
      className="flex justify-start items-center flex-col Apply-form"
    >
      <h2 className="text-color text-3xl font-bold uppercase">APPLY NOW</h2>
      <p className="opacity-75 text-lg">
        And we'll get back to you within 24 hours.
      </p>

      <div className="flex justify-center items-center flex-col mt-[17px] job-application-form-width">
        <FormConent setPosition={setPosition} position={position} />
      </div>
    </div>
  );
};

export default ApplyForm;
