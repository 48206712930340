import React from "react";
import "./About.css";
import { parallaxFollow } from "../../../utils/animations";
import { CaseStudiesProjects } from "../../../data/data-mapping";
import { NavLink } from "react-router-dom";
import animation from "../../../assets/images/about-obelus-doo.png";
import { HiOutlineArrowRight } from "react-icons/hi";

const About = () => {
  window.addEventListener("scroll", () => {
    parallaxFollow("capsule-1");
  });

  return (
    <>
      <div
        className="about-us flex justify-center p-5 mt-10 pb-0"
        id="about-us"
      >
        <div className="about-us-text mx-20">
          <p className="green-color-txt font-bold text-7xl green-text-selection">
            About<br />
            us!
          </p>
          <p className="txt-color 50 my-7 about-us-desc">
            Obelus is a software development company that specialises in
            delivering custom software solutions to businesses of all sizes.
          </p>
          <p className="txt-color 50 my-7 about-us-desc">
            Our team of experienced developers and designers use the latest
            technologies and industry best practices to build software that
            meets your unique needs and requirements.
          </p>
          <p className="txt-color 50 my-7 about-us-desc">
            At Obelus, we are committed to delivering high-quality software
            solutions that meet your needs and exceed your expectations. Contact
            us today to learn more about how we can help you achieve your goals.
          </p>
        </div>
        <div className="capsule-projects flex">
          <div className="capsule-image mx-2">
            <div className="main-capsule">
              <div className="about-animation">
                <img src={animation} alt="About Obelus doo" loading="lazy" decoding="async" />
              </div>
            </div>
          </div>
          <div className="our-projects mt-5 mx-20">
            <div className="projects-text">
              <p className="txt-color font-bold text-5xl" id="our">
                Case
              </p>
              <p className="green-color-txt font-bold text-5xl green-text-selection" id="projects">
                Studies
              </p>
            </div>
            <div className="projects-table">
              <nav className="pt-10 projct-table-list">
                {CaseStudiesProjects.map((project, index) => (
                  <NavLink
                    key={index}
                    className="project-list-item cursor-pointer"
                    to={project.route}
                    aria-label={project.title}
                  >
                    <p className="flex justify-between items-center">
                      {project.title}{" "}
                      <HiOutlineArrowRight className="about-us-arrow-animation" />
                    </p>
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
