import { useEffect, useState } from "react";
import "./approach.css";

const Approach = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const setState = () => {
      if (window.innerWidth <= 950) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    setState();

    window.addEventListener("resize", () => {
      setState();
    });
  }, []);

  return (
    <div className="process-wrp">
      <h2 className="process-title">Approach</h2>
      <p className="approach-title">
        We adopt an Agile development methodology that prioritises flexibility,
        collaboration, and constant iteration. Our approach to software
        development includes the following steps
      </p>

      <div className="container mt-[50px]">
        <ul className="timeline">
          <li>
            <div className="timeline-badge" data-aos="zoom-in">
              <i className="glyphicon glyphicon-check">1</i>
            </div>
            <div className="timeline-panel" data-aos="fade-right">
              <div className="timeline-heading">
                <p className="timeline-title">Discovery </p>
                <p></p>
              </div>
              <div className="timeline-body">
                <p>
                  We work closely with you to understand your business needs,
                  goals, and target audience.
                </p>
              </div>
            </div>
          </li>

          <li className={isVisible ? "" : "timeline-inverted"}>
            <div className="timeline-badge warning" data-aos="zoom-in">
              <i className="glyphicon glyphicon-credit-card">2</i>
            </div>
            <div
              className="timeline-panel"
              data-aos={isVisible ? "fade-right" : "fade-left"}
            >
              <div className="timeline-heading">
                <p className="timeline-title">Design </p>
              </div>
              <div className="timeline-body">
                <p>
                  Our UI/UX designers create wireframes and prototypes that
                  reflect your vision and ensure a seamless user experience.
                </p>
              </div>
            </div>
          </li>

          <li>
            <div className="timeline-badge danger" data-aos="zoom-in">
              <i className="glyphicon glyphicon-credit-card">3</i>
            </div>
            <div className="timeline-panel" data-aos="fade-right">
              <div className="timeline-heading">
                <p className="timeline-title">Development</p>
              </div>
              <div className="timeline-body">
                <p>
                  Our developers build a secure, scalable, and reliable software
                  solution using the latest technologies and best practices.
                </p>
              </div>
            </div>
          </li>

          <li className={isVisible ? "" : "timeline-inverted"}>
            <div className="timeline-badge warning" data-aos="zoom-in">
              <i className="glyphicon glyphicon-credit-card">4</i>
            </div>
            <div
              className="timeline-panel"
              data-aos={isVisible ? "fade-right" : "fade-left"}
            >
              <div className="timeline-heading">
                <p className="timeline-title">Testing </p>
              </div>
              <div className="timeline-body">
                <p>
                  We thoroughly test the software to ensure that it meets your
                  quality standards and is free of bugs and technical issues.
                </p>
              </div>
            </div>
          </li>

          <li>
            <div className="timeline-badge info" data-aos="zoom-in">
              <i className="glyphicon glyphicon-floppy-disk">5</i>
            </div>
            <div className="timeline-panel" data-aos="fade-right">
              <div className="timeline-heading">
                <p className="timeline-title">Deployment </p>
              </div>
              <div className="timeline-body">
                <p>
                  Our team handles the deployment of the software to your
                  production environment and provides support during the
                  transition.
                </p>
              </div>
            </div>
          </li>

          <li className={isVisible ? "" : "timeline-inverted"}>
            <div className="timeline-badge warning" data-aos="zoom-in">
              <i className="glyphicon glyphicon-credit-card">6</i>
            </div>
            <div
              className="timeline-panel"
              data-aos={isVisible ? "fade-right" : "fade-left"}
            >
              <div className="timeline-heading">
                <p className="timeline-title">Support and Maintenance</p>
              </div>
              <div className="timeline-body">
                <p>
                  We provide ongoing maintenance and support services to keep
                  your software running smoothly and up-to-date.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Approach;
