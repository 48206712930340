import React, { useRef, useState } from "react";
import "./newsletter.css";
import ApiService from "../../../services/api.service";
import vars from "../../../data/vars";
import {
  showMessage,
  enableDefaultButton,
  disableDefaultButton,
} from "../../../utils/animations";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Newsletter = () => {
  const [isRequestSent, setIsRequestSent] = useState(false);

  const emailRef = useRef();
  const messageRef = useRef();
  const submitButtonRef = useRef();

  const newsletter = async () => {
    setIsRequestSent(true);

    let email = emailRef.current.value.trim();

    disableDefaultButton(submitButtonRef);

    if (email.length < 1) {
      setIsRequestSent(false);
      showMessage(messageRef, "Email cannot be empty.", true);
      enableDefaultButton(submitButtonRef);
      return;
    }

    if (email.length > 50) {
      setIsRequestSent(false);
      showMessage(messageRef, "Email can be 50 characters long.", true);
      enableDefaultButton(submitButtonRef);
      return;
    }

    if (!email.match(vars.emailRegex)) {
      setIsRequestSent(false);
      showMessage(messageRef, "Please, enter the valid email address.", true);
      enableDefaultButton(submitButtonRef);
      return;
    }

    let res = await ApiService.Newsletter(email);

    if (res.ok) {
      setIsRequestSent(false);
      emailRef.current.value = "";
    }

    setIsRequestSent(false);
    enableDefaultButton(submitButtonRef);
    showMessage(messageRef, res.message, !res.ok);
  };

  return (
    <div className="newsletter-content">
      <div className="flex justify-around items-center flex-col">
        <h2 className="font-bold newsletter-headline">Newsletter</h2>
        <p className="my-1 text-size mt-5 text-center">
          Keep up with our latest news and events. Subscribe to our newsletter.
        </p>
        <input
          ref={emailRef}
          type="email"
          name="email"
          id="newsletter"
          autoComplete="off"
          placeholder="Your email"
        />
        <span
          ref={messageRef}
          className="newsletter-res-message mt-[5px]"
        ></span>
        <div className="btn-height-estimated">
          <button
            ref={submitButtonRef}
            className="subscribe-btn"
            onClick={newsletter}
          >
            {isRequestSent ? (
              <AiOutlineLoading3Quarters className="loading-spinner" />
            ) : (
              "SUBSCRIBE NOW"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
