import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/bike.json";
import animation1 from "../../assets/animations/bike-1.json";
import animation2 from "../../assets/animations/bike-2.json";
import AnimatedView from "../../utils/AnimatedView";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";
import MetaTags from "../../components/Layout/MetaTags";
import MetaTagasData from "../../data/meta-tags";
import Header from "../../components/Layout/Header/Header";

const RentOrBuyEBike = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <MetaTags data={MetaTagasData.RentOrBuyEBike} />
      <Header isInverted={true} />
      <div className="flex justify-start items-start flex-col w-[100%]">
        <div className="case-study-banner">
          <div className="data-wrapper-case-studie">
            <div className="casestudies-banner-animation-formated">
              <AnimatedView animation={animation} isLazyLoading={false} />
            </div>
            <div className="casestudies-txt-studie flex justify-center items-center flex-col">
              <h1 className="headline-for-case-study">Rent or Buy E-Bike</h1>
              <p className="case-study-desc">
                Obelus is proud to offer its e-commerce solution for the Rent or
                Buy E-Bike project. Our platform is designed to provide a
                seamless and user-friendly experience for both customers and
                businesses.
              </p>
              <div className="flex justify-center items-center industry-tag-case-study">
                <span>#</span>
                <p>Retail industry</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">About project</p>
              <p className="normal-text text-space">
                Our platform will allow customers to browse a variety of e-bikes
                available for rent or purchase. They can easily compare the
                features and pricing of different models, and make their
                selection with just a few clicks. Our platform integrates with
                multiple payment gateways, so customers can pay for their rental
                or purchase securely and easily.
              </p>
              <p className="normal-text text-space">
                For businesses, our platform provides a comprehensive management
                system for e-bike inventory and rentals. Businesses can easily
                update their available inventory, set prices, and track rentals
                and sales. They can also manage customer accounts and keep track
                of payments and billing.
              </p>
            </div>
            <div className="data-picture">
              <AnimatedView animation={animation1} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="data-wrp data-wrp-ebike remove-margs-data-wrp">
            <div className="data-picture">
              <AnimatedView animation={animation2} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="normal-text text-space">
                We understand the importance of user experience, so we have
                developed a platform that is easy to use, intuitive, and
                visually appealing. Our development team is committed to
                delivering high-quality software that meets the unique needs of
                your business and provides a seamless user experience.
              </p>
              <p className="normal-text text-space">
                Our team of experienced software developers, project managers,
                and designers are dedicated to delivering high-quality software
                on time and within budget.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default RentOrBuyEBike;
