import React from "react";
import "./style.css";
import "./Banner.css";
import { AiOutlineArrowDown, AiOutlineSmallDash } from "react-icons/ai";
import { FiCircle, FiSquare, FiStar } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
//import BannerVideo from "../../../assets/videos/home-banner.webm";
import AnimatedView from "../../../utils/AnimatedView";
import animation from "../../../assets/animations/tms-2.json"

const AnimatedBanner = () => {
  return (
    <div className="animated-banner">
      <FiStar
        className="star star-1"
        color="#06202b"
        style={{ color: "#06202b" }}
      />
      <FiStar
        className="star star-2"
        color="#06202b"
        style={{ color: "#06202b" }}
      />
      <FiStar
        className="star star-3"
        color="#06202b"
        style={{ color: "#06202b" }}
      />
      <FiCircle
        className="star circle-1"
        color="#06202b"
        style={{ color: "#06202b" }}
      />
      <FiCircle
        className="star circle-2"
        color="#06202b"
        style={{ color: "#06202b" }}
      />
      <FiSquare
        className="star square-1"
        color="#06202b"
        style={{ color: "#06202b" }}
      />
      <FiSquare
        className="star square-2"
        color="#06202b"
        style={{ color: "#06202b" }}
      />
      <AiOutlineSmallDash
        className="star line-1"
        color="#06202b"
        style={{ color: "#06202b" }}
      />
      <div className="animated-banner-content">
        <div className="animated-banner-text">
          <div className="slogan-wrapper">
            <p id="slogan-text-animated">Obelus</p>
            <h1 className="green-text-selection">Solutions that work. Results that matter.</h1>
          </div>
          <nav className="animated-banner-buttons">
            <NavLink
              to="/about-us"
              className="animated-banner-btn"
              aria-label="About Obelus doo"
            >
              About <HiOutlineArrowRight className="link-icon" />{" "}
            </NavLink>
            <NavLink
              to="/services"
              className="animated-banner-btn"
              aria-label="Services Obelus doo"
            >
              Services <HiOutlineArrowRight className="link-icon" />
            </NavLink>
            <NavLink
              to="/contact-us"
              className="animated-banner-btn"
              aria-label="Contact Obelus doo"
            >
              Contact <HiOutlineArrowRight className="link-icon" />
            </NavLink>
          </nav>
        </div>
        <div className="banner-animation">
         <AnimatedView animation={animation} isLazyLoading={false} />
        </div>
      </div>

      <AiOutlineArrowDown
        className="scroll-down-banner scroll-down-banner-2 cursor-pointer"
        onClick={() =>
          document
            .getElementById("about-us")
            .scrollIntoView({ behavior: "smooth" })
        }
      />
    </div>
  );
};

export default AnimatedBanner;
