import React, { useEffect } from "react";
import Home from "./pages/Home";
//import { hydrateRoot } from "react-dom";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./style/index.css";
import "./style/imports.css";

import Careers from "./pages/Careers";
import NotFound from "./pages/NotFound";

import AOS from "aos";
import "aos/dist/aos.css";

import CaseStudies from "./pages/CaseStudies";
import TrafficMonitoringSystem from "./pages/CaseStudies/TrafficMonitoringSystem";
import StandalonePaymentStations from "./pages/CaseStudies/StandalonePaymentStations";
import BookingApp from "./pages/CaseStudies/BookingApp";
import SportsbookSystem from "./pages/CaseStudies/SportsbookSystem";
import CasinoGames from "./pages/CaseStudies/CasinoGames";
import RentOrBuyEBike from "./pages/CaseStudies/RentOrBuyEBike";
import TimeAndAttendanceSystem from "./pages/CaseStudies/TimeAndAttendanceSystem";
import QualifiedVideoAndAudioInterpreting from "./pages/CaseStudies/QualifiedVideoAndAudioInterpreting";
import SocialFunctionalities from "./pages/CaseStudies/SocialFunctionalities";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:id" element={<Home />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-studies/traffic-monitoring-system" element={<TrafficMonitoringSystem />} />
          <Route path="/case-studies/standalone-payment-stations" element={<StandalonePaymentStations />} />
          <Route path="/case-studies/booking-app" element={<BookingApp />} />
          <Route path="/case-studies/sportsbook-system" element={<SportsbookSystem />} />
          <Route path="/case-studies/casino-games" element={<CasinoGames />} />
          <Route path="/case-studies/rent-or-buy-e-bike" element={<RentOrBuyEBike />} />
          <Route path="/case-studies/time-and-attendance-system" element={<TimeAndAttendanceSystem />} />
          <Route path="/case-studies/qualified-video-and-audio-interpreting" element={<QualifiedVideoAndAudioInterpreting />} />
          <Route path="/case-studies/social-functionalities-for-travelers" element={<SocialFunctionalities />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers/:id" element={<Careers />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrateRoot(<App />, rootElement);
// } else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
//}
