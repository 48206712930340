import React from "react";
import FormConent from "../FormConent";
import { AiOutlineClose } from "react-icons/ai";

const ApplyFormMobile = ({
  setPosition,
  position,
  openMobile,
  setOpenMobile,
}) => {
  const baseStyle = "w-[100%] apply-form-mobile flex justify-start items-center flex-col";

  return (
    <div className={`${baseStyle} ${openMobile ? "show-apply-form-mobile" : "hide-apply-form-mobile"}`}>
      <AiOutlineClose
        className="close-apply-form"
        onClick={() => setOpenMobile(false)}
      />
      <h2 className="text-color text-3xl font-bold uppercase mt-[55px]">APPLY NOW</h2>
      <p className="opacity-75 text-lg mb-[25px]">And we'll get back to you within 24 hours.</p>
      <FormConent setPosition={setPosition} position={position} />
    </div>
  );
};

export default ApplyFormMobile;
