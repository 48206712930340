import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/casino.json";
import animation1 from "../../assets/animations/casino-1.json";
import animation2 from "../../assets/animations/casino-2.json";
import AnimatedView from "../../utils/AnimatedView";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";
import MetaTagasData from "../../data/meta-tags";
import MetaTags from "../../components/Layout/MetaTags";
import Header from "../../components/Layout/Header/Header";

const CasinoGames = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTags data={MetaTagasData.CasinoGames} />
      <Header isInverted={true} />
      <div className="flex justify-start items-start flex-col w-[100%]">
        <div className="case-study-banner">
          <div className="data-wrapper-case-studie">
            <div className="casestudies-banner-animation-formated">
              <AnimatedView animation={animation} isLazyLoading={false} />
            </div>
            <div className="casestudies-txt-studie flex justify-center items-center flex-col">
              <h1 className="headline-for-case-study">Casino Games</h1>
              <p className="case-study-desc">
                At Obelus, our team of expert software developers has extensive
                experience working on casino games projects. We have developed a
                wide range of casino games, including slots, table games,
                roulette, instant games, peer-to-peer, and keno, all with a
                variety of features designed to enhance player engagement and
                enjoyment
              </p>
              <div className="flex justify-center items-center industry-tag-case-study">
                <span>#</span>
                <p>Gaming industry</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-1">
          <div className="data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">About project</p>
              <p className="normal-text text-space">
                Casino games we worked on has a lot of features and a range of
                different gameplay mechanics, including free spins, bonus games,
                auto play, chat, and risk. We work closely with our clients to
                understand their specific needs and requirements, and develop
                custom casino games that are tailored to their unique brand and
                audience.
              </p>
            </div>
            <div className="data-picture-2">
              <AnimatedView animation={animation1} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-2">
          <div className="data-wrp text-part-2-responsive">
            <div className="data-picture">
              <AnimatedView animation={animation2} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="normal-text text-space">
                In addition to developing high-quality casino games, we have
                also created a custom casino games administration panel that
                provides our clients with a range of powerful tools and
                features. This panel allows our clients to easily manage and
                update their casino games, view detailed player analytics and
                reports, and access a range of marketing and promotional tools
                designed to drive player engagement and retention.
              </p>
              <p className="normal-text text-space">
                Overall, our team of expert software developers has the skills
                and experience necessary to deliver high-quality casino games
                projects that are designed to drive business success.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default CasinoGames;
