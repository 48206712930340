import React, { useState, useRef } from "react";
import { TbSend } from "react-icons/tb";
import { BsExclamationCircleFill } from "react-icons/bs";
import { AiOutlineFileText, AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import vars from "../../../data/vars";
import ApiService from "../../../services/api.service";
import {
  enableButton,
  disableButton,
  showMessage,
} from "../../../utils/animations";

const FormConent = ({ setPosition, position }) => {
  const [fileState, setFileState] = useState(null);
  const [isTextLoading, setIsTextLoading] = useState(false);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const financialRef = useRef();
  const portfolioRef = useRef();
  const fileRef = useRef();
  const experienceRef = useRef();
  const resMessageRef = useRef();
  const submitButtonRef = useRef();

  const showError = (message) => {
    showMessage(resMessageRef, message, true);
    enableButton(submitButtonRef, "rgba(28, 219, 134, 0.3)", "#00141d");
    setIsTextLoading(false);
    return;
  };

  const applyForJob = async () => {
    let firstName = firstNameRef.current.value.trim();
    let lastName = lastNameRef.current.value.trim();
    let email = emailRef.current.value.trim();
    let phone = phoneRef.current.value.trim();
    let financial = financialRef.current.value;
    let portfolio = portfolioRef.current.value.trim();
    let file = fileState;
    let additionalInformation = experienceRef.current.value.trim();

    setIsTextLoading(true);

    disableButton(submitButtonRef);

    if (firstName.length < 1) {
      showError("First name cannot be empty.");
      return;
    }

    if (lastName.length < 1) {
      showError("Last name cannot be empty.");
      return;
    }

    if (!email.match(vars.emailRegex)) {
      showError("Please, enter the valid email address.");
      return;
    }

    if (!phone.match(vars.phoneRegex)) {
      showError("Please, enter the valid phone number.");
      return;
    } else {
      phone = "+" + phone;
    }

    if (position === "--Select Position--" || position === "") {
      showError("Please, select position");
      return;
    }

    if (financial === "") financial = 0;

    if (portfolio.length > 0 && !portfolio.match(vars.urlRegex)) {
      showError("URL format is not valid.");
      return;
    }

    if (file === null || file === undefined) {
      showError("File cannot be empty.");
      return;
    }

    if (additionalInformation.length > 2000) {
      showError("Experience and additional information can be 2000 characters long.");
      return;
    }

    if (firstName.length > 30) {
      showError("First name can be 30 characters long.");
      return;
    }

    if (lastName.length > 30) {
      showError("Last name can be 30 characters long.");
      return;
    }

    if (email.length > 50) {
      showError("Email can be 50 characters long.");
      return;
    }

    if (phone.length > 50) {
      showError("Phone can be 50 characters long.");
      return;
    }

    if (portfolio.length > 250) {
      showError("Linked or website can be 250 characters long.");
      return;
    }

    let res = await ApiService.applyForJob(
      firstName,
      lastName,
      email,
      phone,
      position,
      portfolio,
      financial,
      additionalInformation,
      file
    );

    if (!res.ok) {
      setIsTextLoading(false);
      showMessage(resMessageRef, res.message, true);
      enableButton(submitButtonRef, "rgba(28, 219, 134, 0.3)", "#00141d");
      return;
    }

    if (res.ok) {
      setIsTextLoading(false);
      enableButton(submitButtonRef, "rgba(28, 219, 134, 0.3)", "#00141d");
      setFileState(null);
      setPosition("--Select Position--");
      showMessage(resMessageRef, res.message, false);

      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      emailRef.current.value = "";
      phoneRef.current.value = "";
      financialRef.current.value = "";
      portfolioRef.current.value = "";
      fileRef.current.value = null;
      experienceRef.current.value = "";
    }
  };

  const getFile = () => {
    const file = fileRef.current.files[0];

    if (!vars.allowedFileTypes.includes(file.type.toLowerCase())) {
      showMessage(resMessageRef, "File type is not acceptable. Acceptable file formats are: doc, docx, pdf", true);
      fileRef.current.value = null;
      return;
    }

    if (vars.convertToMegabytes(file.size) > 10) {
      showMessage(resMessageRef, "The file cannot be larger than 10MB.", true);
      fileRef.current.value = null;
      return;
    }

    setFileState(file);
  };

  return (
    <>
      <div className="w-[100%] flex justify-around items-start mobile-form-apply-field-wrapper">
        <div className="flex justify-start items-start flex-col w-[48.5%] apply-form-mobile-input">
          <label htmlFor="firstName" className="input-form-name">
            first name*
          </label>
          <input
            ref={firstNameRef}
            type="text"
            name="firstName"
            className="form-field w-[100%]"
            required
            aria-label="firstName"
            autoComplete="off"
          />
        </div>
        <div className="flex justify-start items-start flex-col w-[48.5%] ml-[3%] apply-form-mobile-input">
          <label htmlFor="lastName" className="input-form-name">
            last name*
          </label>
          <input
            ref={lastNameRef}
            type="text"
            name="lastName"
            className="form-field w-[100%]"
            required
            aria-label="lastName"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="w-[100%] flex justify-around items-start element-spacing-jobs mobile-form-apply-field-wrapper">
        <div className="flex justify-start items-start flex-col w-[48.5%] apply-form-mobile-input">
          <label htmlFor="email" className="input-form-name">
            email*
          </label>
          <input
            ref={emailRef}
            type="text"
            name="email"
            className="form-field w-[100%]"
            required
            aria-label="email"
            autoComplete="off"
          />
        </div>
        <div className="flex justify-start items-start flex-col w-[48.5%] ml-[3%] apply-form-mobile-input relative">
          <label htmlFor="phone" className="input-form-name">
            phone*
          </label>
          <input
            ref={phoneRef}
            type="text"
            name="phone"
            className="form-field w-[100%]"
            required
            aria-label="phone"
            autoComplete="off"
          />
          <p className="phone-example flex justify-center items-center absolute bottom-[-20px] text-xs text-[#6b6e71] mt-[2px]">
            Example: 381641112223
          </p>
        </div>
      </div>

      <div className="flex justify-start items-start flex-col w-[100%] element-spacing-jobs">
        <label htmlFor="position" className="input-form-name">
          position*
        </label>
        <select
          name="position"
          required
          aria-label="position"
          className="form-field w-[100%]"
          onChange={(e) => setPosition(e.target.value)}
          value={position}
        >
          <option>--Select Position--</option>
          <option>Backend .NET Core Developer</option>
          <option>Node.js Developer</option>
          <option>React.js Developer</option>
          <option>Full-stack .NET Developer</option>
          <option>Developer Intern</option>
        </select>
      </div>
      <div className="w-[100%] flex justify-around items-start element-spacing-jobs mobile-form-apply-field-wrapper">
        <div className="flex justify-start items-start flex-col w-[48.5%] apply-form-mobile-input">
          <label htmlFor="financialExpectations" className="input-form-name">
            Financial expectations <span className="text-[#9ca1a6]">in €</span>
          </label>
          <input
            ref={financialRef}
            type="number"
            aria-label="financialExpectations"
            name="financialExpectations"
            className="form-field w-[100%]"
            min={0}
            autoComplete="off"
          />
        </div>
        <div className="flex justify-start items-start flex-col w-[48.5%] ml-[3%] apply-form-mobile-input">
          <label htmlFor="linkedinOrWebsite" className="input-form-name">
            linkedin or website
          </label>
          <input
            ref={portfolioRef}
            type="text"
            name="linkedinOrWebsite"
            aria-label="linkedinOrWebsite"
            className="form-field w-[100%]"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="flex justify-start items-start flex-col w-[100%] element-spacing-jobs resume-top-mrg">
        {fileState == null && (
          <>
            <div className="flex justify-start items-start flex-col w-[100%] uppload-resume">
              <label htmlFor="resume">Upload your resume/CV*</label>
              <input
                ref={fileRef}
                type="file"
                name="resume"
                multiple={false}
                required
                aria-label="resume"
                id="resume-upload"
                accept={vars.allowedFileTypes}
                onChange={getFile}
              />
            </div>
            <p className="acceptable-note flex justify-center items-center">
              <BsExclamationCircleFill className="mr-1" /> Acceptable file
              formats are: doc, docx, pdf
            </p>
          </>
        )}
        {fileState !== null && (
          <div className="flex justify-start items-center w-[100%] file-preview">
            <div className="flex justify-start items-center flex-1">
              <AiOutlineFileText size={50} className="mr-1" />
              <div className="file-details">
                <p className="w-[270px] truncate font-bold">{fileState.name}</p>
                <p>{vars.formatBytes(fileState.size)}</p>
              </div>
            </div>
            <IoClose
              size={25}
              className="mr-1 cursor-pointer"
              onClick={() => {
                setFileState(null);
                fileRef.current.value = "";
              }}
            />
          </div>
        )}
      </div>

      <div className="flex justify-start items-start flex-col w-[100%] element-spacing-jobs">
        <label htmlFor="additionalInformation" className="input-form-name">
          experience and additional information
        </label>
        <textarea
          ref={experienceRef}
          type="text"
          name="additionalInformation"
          aria-label="additionalInformation"
          className="form-field w-[100%] min-h-[180px] max-h-[300px] job-textarea"
          required
        ></textarea>
      </div>
      <span
        ref={resMessageRef}
        className="apply-job-res-message mt-[5px]"
      ></span>
      <button
        ref={submitButtonRef}
        type="submit"
        aria-label="submit"
        className="send-application flex justify-center items-center"
        onClick={applyForJob}
      >
        {isTextLoading ? (
          <AiOutlineLoading3Quarters className="loading-spinner" />
        ) : (
          <>
            SUBMIT <TbSend size={22} className="ml-2" />
          </>
        )}
      </button>
    </>
  );
};

export default FormConent;
