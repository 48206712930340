import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/payment.json";
import animation1 from "../../assets/animations/payment-1.json";
import animation2 from "../../assets/animations/payment-2.json";
import animation3 from "../../assets/animations/payment-3.json";
import AnimatedView from "../../utils/AnimatedView";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";
import MetaTags from "../../components/Layout/MetaTags";
import MetaTagasData from "../../data/meta-tags";
import Header from "../../components/Layout/Header/Header";

const StandalonePaymentStations = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
   <>
   <MetaTags data={MetaTagasData.StandalonePaymentStations} />
   <Header isInverted={true} />
    <div className="flex justify-start items-start flex-col w-[100%]">
      <div className="case-study-banner">
        <div className="data-wrapper-case-studie">
          <div className="casestudies-banner-animation-formated">
            <AnimatedView animation={animation} isLazyLoading={false} />
          </div>
          <div className="casestudies-txt-studie flex justify-center items-center flex-col">
            <h1 className="headline-for-case-study">
              Standalone payment stations
            </h1>
            <p className="case-study-desc">
              At Obelus, we are experts in developing software for standalone
              payment stations, including those that accept both cash and credit
              card payments. Our team of experienced software developers,
              project managers, and designers have a wealth of experience in
              developing custom software solutions for a variety of industries,
              including the traffic and parking industry.
            </p>
            <div className="flex justify-center items-center industry-tag-case-study">
              <span>#</span>
              <p>Finance Industry</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[100%] flex justify-center items-center text-part-1">
        <div className="data-wrp">
          <div className="flex justify-start items-start flex-col data-text-wrp">
            <p className="section-headline">About project</p>
            <p className="normal-text text-space">
              We understand the importance of providing a secure and reliable
              payment system for standalone payment stations. Our software is
              designed with security and user experience in mind, making it easy
              and efficient for users to pay for their parking or other
              services.
            </p>
          </div>
          <div className="data-picture-2">
            <AnimatedView animation={animation1} />
          </div>
        </div>
      </div>

      <div className="w-[100%] flex justify-center items-center text-part-2">
        <div className="data-wrp remove-margs-data-wrp">
          <div className="data-picture-2">
            <AnimatedView animation={animation2} />
          </div>
          <div className="flex justify-start items-start flex-col data-text-wrp">
            <p className="normal-text text-space">
              Our payment stations software is designed to be flexible and
              scalable, so it can be customized to meet the specific needs of
              your business. We also provide ongoing support and maintenance
              services to ensure the smooth operation of your payment stations,
              so you can focus on growing your business. Standalone payment
              stations software is used by a hundreds of shopping centres,
              hotels, institutions, retail shops and other public and private
              organizations.
            </p>
          </div>
        </div>
      </div>

      <div className="w-[100%] flex justify-center items-center text-part-3">
        <div className="data-wrp remove-margs-data-wrp">
          <div className="flex justify-start items-start flex-col data-text-wrp">
            <p className="normal-text text-space">
              Whether you need a solution for a single standalone payment
              station or a large network of payment stations, we have the
              expertise to deliver a solution that meets your needs. Contact us
              today to learn more about how we can help you improve your payment
              station software.
            </p>
          </div>
          <div className="data-picture-2">
            <AnimatedView animation={animation3} />
          </div>
        </div>
      </div>
    </div>
    <Newsletter />
    <SiteMap />
    <ScrollToTopButton />
   </>
  );
};

export default StandalonePaymentStations;
