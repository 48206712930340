import React, { useState } from "react";
import Header from "../components/Layout/Header/Header";
import SiteMap from "../components/Layout/SiteMap/SiteMap";
import Newsletter from "../components/Layout/Newsletter/Newsletter";
import JobsBanner from "../components/Jobs/Banner/JobsBanner";
import Offers from "../components/Jobs/Offers/Offers";
import ScrollToTopButton from "../components/Layout/ScrollToTopButton";
import MetaTags from "../components/Layout/MetaTags";
import MetaTagasData from "../data/meta-tags";
import LifeAtObelus from "../components/Jobs/LifeAtObelus/LifeAtObelus";
import { Internship } from "../components/Jobs/Internship/Internship";
import { useScroll } from "../hooks/useScroll";

const Careers = () => {
  const [position, setPosition] = useState("--Select Position--");
  const [openMobile, setOpenMobile] = useState(false);
  const metaData = MetaTagasData.Jobs;
  useScroll(metaData);

  return (
    <>
      <MetaTags data={metaData} />
      <Header />
      <JobsBanner />
      <LifeAtObelus />
      <Internship setOpenMobile={setOpenMobile} setPosition={setPosition} />
      <Offers
        openMobile={openMobile}
        setOpenMobile={setOpenMobile}
        setPosition={setPosition}
        position={position}
      />
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default Careers;
