import React from "react";
import "./Offers.css";
import ApplyForm from "../ApplyForm/Base/ApplyForm";
import JobsList from "./JobsList/JobsList";
import ApplyFormButton from "../ApplyForm/Mobile/ApplyFormButton";
import ApplyFormMobile from "../ApplyForm/Mobile/ApplyFormMobile";

const Offers = ({ position, setPosition, openMobile, setOpenMobile }) => {
  return (
    <>
      <div className="w-[100%] flex justify-center items-center md:py-[100px]">
        <div className="w-[90%] flex justify-between items-start job-market">
          <JobsList setPosition={setPosition} setOpenMobile={setOpenMobile} />
          <ApplyForm setPosition={setPosition} position={position} />
          <ApplyFormMobile
            openMobile={openMobile}
            setOpenMobile={setOpenMobile}
            setPosition={setPosition}
            position={position}
          />
        </div>
      </div>
      <ApplyFormButton setOpenMobile={setOpenMobile} />
    </>
  );
};

export default Offers;
