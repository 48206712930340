import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/sportsbook-1.json";
import animation1 from "../../assets/animations/sportsbook.json";
import animation2 from "../../assets/animations/tms-2.json";
import animation3 from "../../assets/animations/casino-1.json";
import animation4 from "../../assets/animations/sportsbook-2.json";
import animation5 from "../../assets/animations/sportsbook-3.json";
import AnimatedView from "../../utils/AnimatedView";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";
import MetaTags from "../../components/Layout/MetaTags";
import MetaTagasData from "../../data/meta-tags";
import Header from "../../components/Layout/Header/Header";

const SportsbookSystem = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <MetaTags data={MetaTagasData.SportsbookSystems} />
      <Header isInverted={true} />
      <div className="flex justify-start items-start flex-col w-[100%]">
        <div className="case-study-banner">
          <div className="data-wrapper-case-studie">
            <div className="casestudies-banner-animation-formated">
              <AnimatedView animation={animation} isLazyLoading={false} />
            </div>
            <div className="casestudies-txt-studie flex justify-center items-center flex-col">
              <h1 className="headline-for-case-study">Sportsbook System</h1>
              <p className="case-study-desc">
                The sportsbook industry is rapidly growing, with more and more
                people placing bets on their favorite sports teams and events
                every day. As a result, it's becoming increasingly important for
                sportsbook operators to provide a seamless and user-friendly
                betting experience for their customers.
              </p>
              <div className="flex justify-center items-center industry-tag-case-study">
                <span>#</span>
                <p>Sports betting industry</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">About project</p>
              <p className="normal-text text-space">
                At Obelus, we have a wealth of experience in developing software
                solutions for the sportsbook industry. Our team of expert
                software developers has a deep understanding of the unique needs
                and challenges of the industry, and we use this knowledge to
                develop cutting-edge software solutions that meet the specific
                requirements of our clients
              </p>
            </div>
            <div className="data-picture-2">
              <AnimatedView animation={animation1} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center flex-col my-[30px] sb-headline">
          <p className="section-headline max-w-[90%] md:max-w-[50%] text-center">
            We develop sports betting software solutions which include:
          </p>
        </div>

        <div className="w-[100%] flex justify-center items-center sb-div">
          <div className="data-wrp data-wrp-sb remove-margs-data-wrp">
            <div className="data-picture-3 data-picture-sb">
              <AnimatedView animation={animation2} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Platform development</p>
              <p className="normal-text text-space">
                We develop custom sportsbook platforms, tailored to meet the
                specific needs of our clients. Platforms are user-friendly,
                secure, and scalable, ensuring that our clients can provide a
                seamless betting experience for their customers.
              </p>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center sb-div">
          <div className="data-wrp remove-margs-data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Game Development</p>
              <p className="normal-text text-space">
                We offer game development services for the sportsbook industry,
                creating new and innovative betting options for customers. Our
                games are designed to be engaging and entertaining, providing
                customers with a unique betting experience.
              </p>
            </div>
            <div className="data-picture-2 data-picture-sb">
              <AnimatedView animation={animation3} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center sb-div">
          <div className="data-wrp data-wrp-sb remove-margs-data-wrp">
            <div className="data-picture data-picture-sb">
              <AnimatedView animation={animation4} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Security systems</p>
              <p className="normal-text text-space">
                Security is of the utmost importance in the sportsbook industry,
                and we take it very seriously. Our security systems are designed
                to protect sensitive customer information and ensure the
                integrity of our clients' operations.
              </p>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center sb-div">
          <div className="data-wrp remove-margs-data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Payment Processing</p>
              <p className="normal-text text-space">
                We develop payment processing systems that are secure, reliable,
                and efficient. Our systems allow customers to quickly and easily
                place bets, deposit funds, and withdraw winnings.
              </p>
              <p className="normal-text text-space">
                At Obelus, our goal is always to provide our clients with the
                highest quality software solutions, tailored to meet their
                specific needs and goals. With our focus on innovation and a
                commitment to delivering results, we have a proven track record
                of success in helping businesses in the sportsbook industry
                achieve their goals.
              </p>
            </div>
            <div className="data-picture-3 data-picture-sb">
              <AnimatedView animation={animation5} />
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default SportsbookSystem;
