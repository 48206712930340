import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

const AnimatedView = ({ animation, loop = true, isLazyLoading = true }) => {
  let animationContainer = useRef();
  let animationRef = useRef();

  useEffect(() => {
    let observer = null;
    let animationLoader = null;

    if (isLazyLoading) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          animationLoader = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: loop,
            animationData: animation,
          });

          animationRef.current = animationLoader;
          observer.disconnect();
        }
      });

      observer.observe(animationContainer.current);
    } else {
      animationLoader = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: loop,
        animationData: animation,
      });
    }

    return () => {
      if (isLazyLoading) {
        if (animationRef.current) {
          animationRef.current.destroy();
        }

        if (observer !== null) {
          observer.disconnect();
        }
      } else {
        if (animationRef.current) {
          animationRef.current.destroy();
        }
      }
    };
  }, [animationContainer, animation, loop, isLazyLoading]);

  return <div className="w-[100%]" ref={animationContainer} />;
};

export default AnimatedView;
