import { useState, useEffect } from "react";
import { FaChevronUp } from "react-icons/fa";
import "./ApplyFormMobile.css";

const ApplyFormButton = ({ setOpenMobile }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let openPositionsHeight = document
      .getElementById("open-positions")
      .getBoundingClientRect().top;

    if (window.innerWidth < 950) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > openPositionsHeight && window.innerWidth < 950) {
          setVisible(true);
          document.body.style.overflow = "hidden";
        }

        if (window.scrollY < openPositionsHeight && window.innerWidth < 950) {
          setVisible(false);
          document.body.style.overflow = "scroll";
        }
      });
    }
  }, []);

  return (
    <button
      onClick={() => setOpenMobile(true)}
      className={`${
        visible === true ? "Apply-btn-mob-show" : "Apply-btn-mob-hide"
      } Apply-button-mobile`}
    >
      Apply <FaChevronUp className="ml-2" />
    </button>
  );
};

export default ApplyFormButton;
