import React from "react";
import "./JobOpenings.css";
import { NavLink } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const JobOpenings = () => {
  return (
    <div className="flex justify-center items-center flex-col job-openings-bg">
      <h2 className="job-openings-headline">Interested in joining our team?</h2>
      <p className="openings-description">
        We're always looking for talent and good conversation, preferably over a
        nice cup of coffee or tea.
      </p>
      <div className="flex justify-center items-center job-openings-button-wrappers">
        <NavLink
          className="openings-button flex justify-around items-center"
          to="/careers/open-positions"
          aria-label="Open positions Obelus doo"
        >
          Open positions <AiOutlineArrowRight size={30} className="ml-2" />
        </NavLink>
        <NavLink
          aria-label="Life Obelus doo"
          to="/careers/life-at-obelus"
          className="openings-button flex justify-around items-center life-at-obelus-btn"
        >
          Life at Obelus <AiOutlineArrowRight size={30} className="ml-2" />
        </NavLink>
      </div>
    </div>
  );
};

export default JobOpenings;
