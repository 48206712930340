import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/translations.json";
import animation1 from "../../assets/animations/translations-1.json";
import animation2 from "../../assets/animations/translations-3.json";
import AnimatedView from "../../utils/AnimatedView";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";
import Header from "../../components/Layout/Header/Header";
import MetaTags from "../../components/Layout/MetaTags";
import MetaTagasData from "../../data/meta-tags";

const QualifiedVideoAndAudioInterpreting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTags data={MetaTagasData.QualifiedAudioAndVideoInterpreting} />
      <Header isInverted={true} />
      <div className="flex justify-start items-start flex-col w-[100%]">
        <div className="case-study-banner">
          <div className="data-wrapper-case-studie">
            <div className="casestudies-banner-animation-formated">
              <AnimatedView animation={animation} loop={false} isLazyLoading={false} />
            </div>
            <div className="casestudies-txt-studie flex justify-center items-center flex-col">
              <h1 className="headline-for-case-study">
                Qualified Video and Audio Interpreting
              </h1>
              <p className="case-study-desc">
                At Obelus, we have extensive experience in delivering successful
                software projects in the field of video and audio interpreting.
                Our team has the expertise and knowledge to create a seamless
                and efficient solution for delivering interpreting services to
                customers over video or audio.
              </p>
              <div className="flex justify-center items-center industry-tag-case-study">
                <span>#</span>
                <p>Translation industry</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-1 text-part-1-qualified-rtc">
          <div className="data-wrp remove-margs-data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">About project</p>
              <p className="normal-text text-space">
                Our Qualified Video and Audio Interpreting platform enables
                organizations to access professional interpreters quickly and
                easily, 24/7, from anywhere in the world. The platform is
                designed to be user-friendly and easy to use, with features such
                as automated scheduling, real-time reporting, and secure payment
                processing
              </p>
            </div>
            <div className="data-picture">
              <AnimatedView animation={animation1} loop={false} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center text-part-2">
          <div className="data-wrp remove-margs-data-wrp">
            <div className="data-picture-2">
              <AnimatedView animation={animation2} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="normal-text text-space">
                Whether you are a government agency, a healthcare provider, or a
                business with a global customer base, our Qualified Video and
                Audio Interpreting platform can help you to provide your
                customers with fast, reliable, and high-quality interpreting
                services. Contact us today to learn more about how we can help
                you to achieve your goals with this exciting new technology.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default QualifiedVideoAndAudioInterpreting;
