import React from "react";
import "./style.css";
import animation from "../../../assets/animations/case-studies.json";
import AnimatedView from "../../../utils/AnimatedView";

const Introduction = () => {
  return (
    <div className="case-studies-introduction">
      <div className="data-wrapper-case">
        <div className="casestudies-banner-animation">
          <AnimatedView animation={animation} />
        </div>
        <div className="casestudies-txt flex justify-center items-center flex-col">
          <h1>Case studies</h1>
          <p>
            At Obelus, we are proud to have worked on a variety of exciting
            projects for clients in a wide range of industries.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
