import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/tas.json";
import animation1 from "../../assets/animations/tas-1.json";
import animation2 from "../../assets/animations/tas-2.json";
import animation3 from "../../assets/animations/tas-3.json";
import animation4 from "../../assets/animations/tas-4.json";
import AnimatedView from "../../utils/AnimatedView";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";
import MetaTags from "../../components/Layout/MetaTags";
import MetaTagasData from "../../data/meta-tags";
import Header from "../../components/Layout/Header/Header";

const TimeAndAttendanceSystem = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <MetaTags data={MetaTagasData.TimeAndAttendanceSystem} />
      <Header isInverted={true} />
      <div className="flex justify-start items-start flex-col w-[100%]">
        <div className="case-study-banner">
          <div className="data-wrapper-case">
            <div className="casestudies-banner-animation-formated">
              <AnimatedView animation={animation} isLazyLoading={false} />
            </div>
            <div className="casestudies-txt flex justify-center items-center flex-col">
              <h1 className="headline-for-case-study">
                Time and Attendance System
              </h1>
              <p className="case-study-desc">
                A time and attendance system is a software solution used to
                track and manage employee work hours, schedules, and attendance
                records
              </p>
              <div className="flex justify-center items-center industry-tag-case-study">
                <span>#</span>
                <p>Human resources</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">About project</p>
              <p className="normal-text text-space">
                This system is typically used in human resources departments to
                manage payroll, calculate overtime and paid time off, and
                monitor compliance with labor laws and company policies. A time
                and attendance system can also improve the accuracy and
                efficiency of the time tracking process, reducing manual errors
                and reducing the time and resources required for manual data
                entry.
              </p>
            </div>
            <div className="data-picture">
              <AnimatedView animation={animation1} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center flex-col my-[30px] tas-headline">
          <p className="section-headline max-w-[90%] md:max-w-[50%] text-center">
            Some features of a time and attendance system include:{" "}
          </p>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="data-wrp data-wrp-tas">
            <div className="data-picture-2">
              <AnimatedView animation={animation2} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Time tracking</p>
              <p className="normal-text text-space">
                The system allows employees to clock in and out, track breaks,
                and record hours worked. The data is automatically stored in a
                centralized database for easy access and analysis. Scheduling:
                The system provides a visual calendar for scheduling employee
                shifts, and can automatically generate schedules based on
                predefined rules and constraints.
              </p>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="data-wrp rm-t-marg">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Reporting</p>
              <p className="normal-text text-space">
                The system generates various reports, such as payroll reports,
                time off balance reports, and attendance reports, which can be
                customized and exported to various formats. Integration: The
                system can integrate with other HR software, such as payroll
                systems and HR management systems, to streamline the time and
                attendance process and eliminate the need for manual data entry.
              </p>
            </div>
            <div className="data-picture-2">
              <AnimatedView animation={animation3} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="data-wrp data-wrp-tas">
            <div className="data-picture-2">
              <AnimatedView animation={animation4} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Accessibility</p>
              <p className="normal-text text-space">
                The system can be accessed from multiple devices, including
                desktop computers, laptops, and mobile devices, allowing
                employees to track their time and view schedules from anywhere,
                at any time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default TimeAndAttendanceSystem;
