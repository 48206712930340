export const parallaxFollow = (cssClass) => {
  const parallaxElement = document.querySelectorAll(`.${cssClass}`);
  const parallaxQuantity = parallaxElement.length;

  window.requestAnimationFrame(() => {
    for (let i = 0; i < parallaxQuantity; i++) {
      const currentElement = parallaxElement[i];
      const windowTop = window.pageYOffset;
      const elementTop = currentElement.offsetTop;
      const elementHeight = currentElement.offsetHeight;
      const viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5;
      const scrolled = windowTop - elementTop + viewPortHeight;

      currentElement.style.transform = `translate3d(0, ${
        scrolled * -0.15
      }px, 0)`;
    }
  });
};

export const slideDown = (id) => {
  let container = document.getElementById(id);

  container.classList.add("active");
  container.style.height = "auto";

  var height = container.clientHeight + "px";

  container.style.height = "0px";

  setTimeout(() => {
    container.style.height = height;
    container.style.display = "flex";
  }, 10);
};

export const slideUp = (id) => {
  let container = document.getElementById(id);
  container.style.height = "0px";

  container.addEventListener(
    "transitionend",
    () => {
      container.classList.remove("active");
    },
    { once: true }
  );
};

export const showMessage = (messageRef, message, error = false) => {
  messageRef.current.style.display = "block";
  messageRef.current.style.color = error ? "#e74c3c" : "#1dc579";
  messageRef.current.innerText = message;
  let timeout = setTimeout(() => {
    messageRef.current.style.display = "none";
    clearTimeout(timeout);
  }, 3000);
};

export const enableButton = (buttonRef, background, color) => {
  buttonRef.current.style.background = background;
  buttonRef.current.style.color = color;
  buttonRef.current.style.pointerEvents = "initial";
  buttonRef.current.disabled = false;
};

export const disableButton = (buttonRef) => {
  buttonRef.current.style.background = "#cccccc";
  buttonRef.current.style.color = "#707070";
  buttonRef.current.style.pointerEvents = "none";
  buttonRef.current.disabled = true;
};

export const enableDefaultButton = (buttonRef) => {
  buttonRef.current.disabled = false;
  buttonRef.current.style.pointerEvents = "initial";
};

export const disableDefaultButton = (buttonRef) => {
  buttonRef.current.disabled = true;
  buttonRef.current.style.pointerEvents = "none";
};
