import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/logo-white.png";
import LogoBlack from "../../../assets/images/logo.png";
import "./Header.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { NavLink } from "react-router-dom";
import useDevice from "../../../hooks/useDevice";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

const Header = ({ visibility, isInverted }) => {
  const [visible, setVisible] = useState(visibility ? true : false);
  const [visibleInverted, setVisibleInverted] = useState(
    isInverted ? true : false
  );
  const device = useDevice();

  useEffect(() => {
    if (!visibility) {
      const BREAKPOINT = window.innerWidth > 500 ? 90 : 45;

      window.addEventListener("scroll", () => {
        if (window.scrollY > BREAKPOINT) {
          setVisible(true);
          setVisibleInverted(false);
        } else {
          setVisible(false);

          if (isInverted === true) {
            setVisibleInverted(true);
          }
        }
      });
    }
  }, [visibility, isInverted]);

  const openBurger = () => {
    document.body.style.overflow = "hidden";

    const burgerMenu = document.getElementById("burger-menu");
    burgerMenu.classList.add("open-burger");
    burgerMenu.classList.replace("hidden-burger", "burger-visible");
    document.getElementById("header").style.zIndex = 1;

    let timeout = setTimeout(() => {
      burgerMenu.classList.remove("open-burger");
      clearTimeout(timeout);
    }, 250);
  };

  const linkClass = `${
    !visible && !visibleInverted ? "text-[#fff]" : "txt-color"
  } link`;

  return (
    <>
      {(device === 0 || device === 1) && <BurgerMenu />}
      <header
        className={`w-[100%] flex justify-between items-center header header fixed top-0  ${
          visibleInverted ? "inverted-header" : ""
        } ${visible && "header-white "}`}
        id="header"
      >
        <NavLink to="/" aria-label="Obelus doo Home page">
          <img
            src={visible || visibleInverted ? LogoBlack : Logo}
            alt="Obelus doo logo"
            className="h-[70px] cursor-pointer header-logo"
          />
        </NavLink>
        <nav className="flex justify-start items-center">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? linkClass + " active-link" : linkClass
            }
            aria-label="Home page"
          >
            Home
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) =>
              isActive ? linkClass + " active-link" : linkClass
            }
            aria-label="About us page"
          >
            About
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive ? linkClass + " active-link" : linkClass
            }
            aria-label="Services page"
          >
            Services
          </NavLink>
          <NavLink
            to="/case-studies"
            className={({ isActive }) =>
              isActive ? linkClass + " active-link" : linkClass
            }
            aria-label="Case studies page"
          >
            Case studies
          </NavLink>
          <NavLink
            to="/careers"
            className={({ isActive }) =>
              isActive ? linkClass + " active-link" : linkClass
            }
            aria-label="Careers page"
          >
            Careers
          </NavLink>
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              isActive ? linkClass + " active-link" : linkClass
            }
            aria-label="Contact us page"
          >
            Contact
          </NavLink>
        </nav>
        <RxHamburgerMenu
          className="header-icon-burger"
          size={30}
          style={{ color: visible || visibleInverted ? "#00141d" : "#ffffff" }}
          onClick={openBurger}
        />
      </header>
    </>
  );
};

export default Header;
