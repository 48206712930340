import React from "react";
import { ActiveJobsList } from "../../../../data/jobs";
import Job from "./Job/Job";

const JobsList = ({ setPosition, setOpenMobile }) => {
  return (
    <div
      className="flex justify-start items-center md:items-start flex-col"
      id="open-positions"
    >
      <h2 className="text-color text-4xl md:text-5xl text-center font-bold">Open positions</h2>
      <p className="text-xl text-center md:text-left mt-4 md:mt-0">
        Take a look at our current job openings and apply today. We can't wait
        to hear from you!
      </p>
      <div className="flex justify-start items-start flex-col w-[100%]">
        {ActiveJobsList.map((job, index) => (
          <Job
            job={job}
            id={index}
            key={index}
            setOpenMobile={setOpenMobile}
            setPosition={setPosition}
          />
        ))}
      </div>
    </div>
  );
};

export default JobsList;
