import React from "react";
import "./services.css";
import { Frameworks, ServicesMapping } from "../../../data/data-mapping";
import Approach from "../Approach/Approach";
import useDevice from "../../../hooks/useDevice";
import { IoClose } from "react-icons/io5";

const Services = () => {
  const device = useDevice();

  const openPopup = (index) => {
    document.body.style.overflow = "hidden";
    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    const serviceMobileWrapper = document.getElementById(
      "service-mobile-wrapper"
    );

    serviceMobileWrapper.style.display = "block";
    serviceMobileWrapper.classList.add("service-mobile-open");

    document.getElementById("mobile-service-heading").innerText =
      ServicesMapping[index].name;
    document.getElementById("mobile-service-text").innerText =
      ServicesMapping[index].description;

    let timeout = setTimeout(() => {
      serviceMobileWrapper.classList.remove("service-mobile-open");
      clearTimeout(timeout);
    }, 200);
  };

  const closePopup = () => {
    const serviceMobileWrapper = document.getElementById(
      "service-mobile-wrapper"
    );

    serviceMobileWrapper.classList.add("service-mobile-close");
    document.body.style.overflow = "scroll";
    document.getElementsByTagName("html")[0].style.overflow = "scroll";

    let timeout = setTimeout(() => {
      serviceMobileWrapper.classList.remove("service-mobile-close");
      serviceMobileWrapper.style.display = "none";
      clearTimeout(timeout);
    }, 200);
  };

  const ServiceMobilePopup = () => {
    return (
      <div className="service-mobile-wrapper" id="service-mobile-wrapper">
        <div className="service-mobile-popup" id="service-mobile-popup">
          <IoClose
            className="burger-close"
            size={50}
            style={{ color: "#00141d" }}
            onClick={closePopup}
          />
          <div className="service-mobile-desc">
            <div
              id="mobile-service-heading"
              className="font-bold text-2xl"
            ></div>
            <p id="mobile-service-text"></p>
          </div>
        </div>
      </div>
    );
  };

  const Service = ({ data, index }) => {
    return (
      <article
        className="service-block"
        onClick={() => device === 0 && openPopup(index)}
      >
        <div className="service-content">
          {data.icon}
          <h2>{data.name}</h2>
        </div>
        <div className="showing-related-data flex justify-center items-center">
          <p className="w-[90%] m-auto flex justify-start items-start flex-col text-lg overflow-y-auto max-h-[90%]">
            {data.description}
          </p>
        </div>
      </article>
    );
  };

  const Framework = ({ data, index }) => {
    return (
      <article
        className="framework"
        data-aos="fade-up"
        data-aos-delay={`${index * 100}`}
      >
        <img
          src={data.image}
          alt={`Obelus doo ${data.title}`}
          loading="lazy"
          decoding="async"
          className="framwork-image"
        />
        <p>{data.title}</p>
      </article>
    );
  };

  return (
    <>
      <ServiceMobilePopup />
      <div
        className="w-[100%] flex justify-center items-center flex-col technologies"
        id="services"
      >
        <div className="green-devider"></div>
        <h2 className="font-bold technology-title">What we can do for you?</h2>
        <p className="mt-5 text-center mb-7 technology-text">
          We are dedicated to providing comprehensive software development
          services to meet the needs of a wide range of industries.
        </p>
        <div className="technology-wrp">
          {ServicesMapping.map((data, index) => (
            <Service data={data} key={index} index={index} />
          ))}
        </div>
        <h2 className="font-bold technology-title">
          Technologies we mostly use
        </h2>

        <div className="frameworks">
          {Frameworks.map((data, index) => (
            <Framework data={data} key={index} index={index} />
          ))}
        </div>
        <div className="margin-top-des"></div>
        <Approach />
        <div className="margin-top-des"></div>
        <div className="mt-5 technology-descr">
          <p className="service-description">
            We offer international work experience and involvement with
            innovative technologies and solutions.
          </p>
        </div>
      </div>
    </>
  );
};

export default Services;
