const MetaTagasData = {
  Home: {
    title: "Solutions that work. Results that matter.",
    description:
      "Obelus is a software development company that specialises in delivering custom software solutions to businesses of all sizes.",
    route: "",
  },
  CaseStudies: {
    title: "Case studies",
    description:
      "At Obelus, we are proud to have worked on a variety of exciting projects for clients in a wide range of industries",
    route: "/case-studies",
  },
  Jobs: {
    title: "Careers",
    description:
      "We don't just see our team as employees, we see them as members of our family.",
    route: "/careers",
  },
  NotFound: {
    title: "404",
    description: "Page not found",
    route: "*",
  },
  CaseStudy: {
    title: "",
    description: "",
    route: "/case-studies",
  },
  TrafficMonitoringSystem: {
    title: "Traffic monitoring system",
    description: "A solution helps national parks to monitor and collect fees for using motor vehicles, improving revenue and enhancing the user experience.",
    route: "/case-studies/traffic-monitoring-system"
  },
  StandalonePaymentStations: {
    title: "Standalone payment stations",
    description: "A software for standalone payment stations, including those that accept both cash and credit card payments.",
    route: "/case-studies/standalone-payment-stations"
  },
  BookingApp: {
    title: "Booking app",
    description: "Online booking parking log is designed to streamline the parking process and make it easy and convenient for users to reserve and pay for parking spaces.",
    route: "/case-studies/booking-app",
  },
  SportsbookSystems: {
    title: "Sportsbook System",
    description: "A comprehensive platform for sports betting that provides a seamless and secure experience for users.",
    route: "/case-studies/sportsbook-system"
  },
  RentOrBuyEBike: {
    title: "Rent or Buy E-Bike",
    description: "A platform that allows users to rent or purchase electric bikes for a fun and eco-friendly mode of transportation.",
    route: "/case-stuides/rent-or-buy-e-bike"
  },
  TimeAndAttendanceSystem: {
    title: "Time and Attendance System",
    description: "A solution that makes it easy for businesses to track employee time and attendance, calculate payroll, and monitor compliance with labour laws.",
    route: "/case-studies/time-and-attendance-system"
  },
  QualifiedAudioAndVideoInterpreting: {
    title: "Qualified Video and Audio Interpreting",
    description: "A solution that enables organisations to access professional interpreters quickly and easily, 24/7, from anywhere in the world.",
    route: "/case-studies/qualified-video-and-audio-interpreting"
  },
  SocialFunctionalitiesForTravelers: {
    title: "Social functionalities for travelers",
    description: "Social functionalities aimed at easier introduction and socialization of users traveling to the same destinations.",
    route: "/case-studies/social-functionalities-for-travelers"
  },
  CasinoGames: {
    title: "Casino Games",
    description: "Obelus has worked to develop solutions for the gaming industry, including online casinos. these solutions are designed to provide a seamless gaming experience and ensure the fairness and integrity of online gaming.",
    route: "/case-studies/casino-games"
  }
};

export default MetaTagasData;
