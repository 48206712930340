import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > window.screen.height) {
        setVisible(true);
      }

      if (window.scrollY < window.screen.height) {
        setVisible(false);
      }
    });
  }, []);

  return (
    <div className={`scroll-to-top-btn ${visible ? "show-to-top-btn" : "hide-to-top-button"}`} onClick={() => {
      window.scrollTo(0, 0);
    }}>
      <AiOutlineArrowUp size={20} color="#fff" />
    </div>
  );
};

export default ScrollToTopButton;
