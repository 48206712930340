import React, { useEffect } from "react";
import "./style.css";
import "../../components/CaseStudies/Introduction/style.css";
import animation from "../../assets/animations/tms.json";
import animation1 from "../../assets/animations/tms-1.json";
import animation2 from "../../assets/animations/booking-1.json";
import animation3 from "../../assets/animations/payment.json";
import animation4 from "../../assets/animations/tas-1.json";
import animation5 from "../../assets/animations/tms-2.json";
import AnimatedView from "../../utils/AnimatedView";
import Newsletter from "../../components/Layout/Newsletter/Newsletter";
import SiteMap from "../../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../../components/Layout/ScrollToTopButton";
import Header from "../../components/Layout/Header/Header";
import MetaTags from "../../components/Layout/MetaTags";
import MetaTagasData from "../../data/meta-tags";

const TrafficMonitoringSystem = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <MetaTags data={MetaTagasData.TrafficMonitoringSystem} />
      <Header isInverted={true} />
      <div className="flex justify-start items-start flex-col w-[100%]">
        <div className="case-study-banner">
          <div className="data-wrapper-case-studie data-wrapper-case-studie-tms">
            <div className="casestudies-banner-animation-formated">
              <AnimatedView animation={animation} isLazyLoading={false} />
            </div>
            <div className="casestudies-txt-studie flex justify-center items-center flex-col">
              <h1 className="headline-for-case-study">
                Traffic monitoring system
              </h1>
              <p className="case-study-desc case-study-desc-tms">
                The National Park Service plays a critical role in preserving
                and protecting our nation's natural resources and cultural
                heritage. However, managing the flow of visitors and vehicles
                into and through the parks can be a complex and challenging
                task, requiring the right tools and systems to ensure that
                everything runs smoothly.
              </p>
              <div className="flex justify-center items-center industry-tag-case-study">
                <span>#</span>
                <p>Traffic industry</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">About project</p>
              <p className="normal-text text-space">
                At Obelus, we understand the unique needs and challenges of the
                National Park Service and have developed a range of software
                solutions to help manage vehicle entry and collection. Our team
                of expert software developers has a deep understanding of the
                industry, and we use this knowledge to deliver custom solutions
                that meet the specific requirements of our clients.
              </p>
            </div>
            <div className="data-picture-2">
              <AnimatedView animation={animation1} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center flex-col my-[30px] tms-headline">
          <p className="section-headline max-w-[90%] md:max-w-[50%] text-center">
            Our vehicle entry and collection systems for the National Park
            Service include:
          </p>
        </div>

        <div className="w-[100%] flex justify-center items-center tms-div-1">
          <div className="data-wrp data-tms remove-margs-data-wrp">
            <div className="data-picture-2 data-picture-tms">
              <AnimatedView animation={animation2} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Vehicle Entry Management</p>
              <p className="normal-text text-space">
                We develop software solutions to help the National Park Service
                manage the flow of vehicles into the parks. Our solutions allow
                for real-time monitoring of vehicle entry, ensuring that only
                authorized vehicles can enter the park.
              </p>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center tms-div-2">
          <div className="data-wrp remove-margs-data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Revenue Collection</p>
              <p className="normal-text text-space">
                Our revenue collection systems help the National Park Service
                collect fees from visitors for entry into the parks. Our systems
                are designed to be secure, reliable, and efficient, ensuring
                that the National Park Service can effectively manage its
                revenue streams.
              </p>
            </div>
            <div className="data-picture-3 data-picture-tms">
              <AnimatedView animation={animation3} />
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center tms-div-3">
          <div className="data-wrp data-tms remove-margs-data-wrp">
            <div className="data-picture data-picture-tms">
              <AnimatedView animation={animation4} />
            </div>
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Reporting and Analytics</p>
              <p className="normal-text text-space">
                We offer reporting and analytics systems to help the National
                Park Service track and analyze its revenue and visitor data. Our
                systems provide real-time information, allowing the National
                Park Service to make data-driven decisions to improve its
                operations.
              </p>
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center tms-div-4">
          <div className="data-wrp">
            <div className="flex justify-start items-start flex-col data-text-wrp">
              <p className="section-headline">Customer Experience</p>
              <p className="normal-text text-space">
                Our vehicle entry and collection systems are designed to improve
                the customer experience. Our systems are user-friendly, allowing
                visitors to quickly and easily pay for entry into the park.
              </p>
            </div>
            <div className="data-picture-2 data-picture-tms">
              <AnimatedView animation={animation5} />
            </div>
          </div>
        </div>

        <div className="bottom-case-studies override-case-study-bottom">
          <p className="txt-color">
            At Obelus our goal is always to provide our clients with the highest
            quality software solutions, tailored to meet their specific needs
            and goals. With our focus on innovation and a commitment to
            delivering results, we have a proven track record of success in
            helping the National Park Service achieve its goals.
          </p>
        </div>
      </div>
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default TrafficMonitoringSystem;
