import React from "react";
import { Helmet as Head } from "react-helmet";

const MetaTags = ({ data }) => {
  const title = `Obelus doo - ${data.title}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={data.description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={data.description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={data.description} />
      <meta property="og:url" content={`https://obelus.rs${data.route}`} />
      <meta itemprop="name" content={title} />
      <meta itemprop="title" content={title} />
      <meta itemprop="description" content={data.description} />
      <meta itemprop="url" content={data.route} />
      <meta name="apple-mobile-web-app-title" content={data.title} />
    </Head>
  );
};

export default MetaTags;
