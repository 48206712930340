import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import MetaTags from "../components/Layout/MetaTags";
import MetaTagasData from "../data/meta-tags";

const NotFound = () => {
  return (
    <>
      <MetaTags data={MetaTagasData.NotFound} />
      <div className="bg-white h-[100vh] flex justify-center items-center flex-col">
        <img
          src={Logo}
          alt="Obelus logo"
          className="not-found-logo"
          width={250}
          height={78}
          loading="lazy"
          decoding="async"
        />
        <div>
          <h2
            className="text-[200px] black-color-txt"
            style={{ lineHeight: "200px" }}
          >
            <b>404</b>
          </h2>
          <p className="black-color-txt text-[50px]">
            <b>Page not found!</b>
          </p>
          <p className="text-xl max-w-[350px] my-4 black-color-txt">
            We're sorry, the page you requested could not be found. Please, go
            back to the homepage.
          </p>
          <NavLink
            to="/"
            className="back-to-home mt-7"
            aria-label="Home not found page"
          >
            GO HOME
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default NotFound;
