import React from "react";
import "./style.css";
import { CaseStudiesProjects } from "../../../data/data-mapping";
import { ImArrowRight2 } from "react-icons/im";
import { NavLink } from "react-router-dom";

const ProjectsList = () => {
  const Project = ({ data }) => {
    return (
      <article className="case-studies-project">
        <div className="w-[100%] flex justify-start items-start flex-col">
          <img
            src={data.image}
            alt={`Obelus doo ${data.title}`}
            loading="lazy"
            decoding="async"
          />
          <div className="project-data">
            <h2>{data.title}</h2>
            <div className="flex justify-start items-center my-[15px] industry-tag font-bold">
              <p className="green-color-txt">#</p>
              <p className="ml-2">{data.industry}</p>
            </div>
            <p className="case-studies-project-desc">{data.description}</p>
          </div>
        </div>
        <NavLink
          aria-label={`Obelus doo ${data.title}`}
          className="connect-button-case"
          to={data.route}
        >
          <p>Read more</p>
          <ImArrowRight2 className="connect-button-icon-case" />
        </NavLink>
      </article>
    );
  };

  return (
    <div className="case-studies-data-list w-[100%] flex justify-center items-center flex-col">
      <span>
        Here are just a few examples of the case studies and projects we have
        completed:
      </span>
      <section className="case-studies-mapping">
        {CaseStudiesProjects.map((data, index) => (
          <Project key={index} data={data} />
        ))}
      </section>
      <div className="bottom-case-studies">
        <p className="txt-color">
          These are just a few examples of the innovative and reliable software
          products we offer. Our team works closely with our clients to
          understand their specific needs and requirements, and to deliver
          custom-tailored solutions that meet their objectives. Contact us today
          to learn more about how we can help you with your software needs.
        </p>
      </div>
    </div>
  );
};

export default ProjectsList;
