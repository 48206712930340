const env = process.env;

const vars = {
  server: env.REACT_APP_SERVER,
  emailRegex:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  phoneRegex: /^\d+$/,
  urlRegex: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
  allowedFileTypes: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ],
  formatBytes: (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  convertToMegabytes: (bytes) => {
    return Math.round((bytes / Math.pow(1024, 2)) * 100) / 100;
  },
};

export default vars;
