import React from "react";
import "./SiteMap.css";
import Logo from "../../../assets/images/logo-white.png";
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineLinkedin,
} from "react-icons/ai";

import { GrMail } from "react-icons/gr";
import { BsTelephoneFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { NavLink } from "react-router-dom";

const SiteMap = () => {
  return (
    <div className="sitemap flex justify-between items-center flex-col">
      <div className="site-map-devider"></div>
      <div className="w-[80%] flex justify-around items-start  sitemap-blocks">
        <div className="flex justify-start items-start flex-col">
          <img
            src={Logo}
            alt="Obelus doo logo"
            width={300}
            height={93}
            className="logo-sitemap"
            loading="lazy"
            decoding="async"
          />
          <p className="white-txt pb-0 mb-0 mt-2 slogan-with">
            <i>
              <sub>&ldquo;&nbsp;</sub>
              <span id="slogan-text">
                Solutions that work. Results that matter. Obelus.
              </span>
              <sup>&nbsp;&#8221;</sup>
            </i>
          </p>
          <div className="flex justify-around items-start mt-2">
            <a
              href="https://www.instagram.com/obelus_doo/"
              target="_blank"
              rel="noreferrer"
              className="social-media-block-sitemap"
              aria-label="Obelus doo Instagram account"
            >
              <AiOutlineInstagram color="#1cdb86" size={40} />
            </a>
            <a
              href="https://www.facebook.com/obelus.doo"
              target="_blank"
              rel="noreferrer"
              className="social-media-block-sitemap"
              aria-label="Obelus doo Facebook account"
            >
              <AiOutlineFacebook color="#1cdb86" size={40} />
            </a>
            <a
              href="https://www.linkedin.com/company/obelusdoo/"
              target="_blank"
              rel="noreferrer"
              className="social-media-block-sitemap"
              aria-label="Obelus doo Linkedin account"
            >
              <AiOutlineLinkedin color="#1cdb86" size={40} />
            </a>
          </div>
        </div>
        <div className="flex justify-center items-start flex-col sitemap-block">
          <p className="green-color-txt text-2xl green-text-selection">SITE LINKS</p>
          <div className="flex justify-start items-start flex-col mt-3">
            <NavLink
              to="/"
              className="white-txt sitemap-txt"
              aria-label="Home sitemap page"
            >
              Home
            </NavLink>
            <NavLink
              to="/about-us"
              className="white-txt sitemap-txt"
              aria-label="About us sitemap page"
            >
              About
            </NavLink>
            <NavLink
              to="/contact-us"
              className="white-txt sitemap-txt"
              aria-label="Contact us sitemap page"
            >
              Contact
            </NavLink>
            <NavLink
              to="/services"
              className="white-txt sitemap-txt"
              aria-label="Services sitemap page"
            >
              Services
            </NavLink>
            <NavLink
              to="/case-studies"
              className="white-txt sitemap-txt"
              aria-label="Case studies sitemap page"
            >
              Case studies
            </NavLink>
          </div>
        </div>

        <div className="flex justify-center items-start flex-col sitemap-block">
          <p className="green-color-txt text-2xl green-text-selection">CAREERS</p>
          <div className="flex justify-start items-start flex-col mt-3">
            <NavLink
              to="/careers"
              className="white-txt sitemap-txt"
              aria-label="Careers sitemap page"
            >
              Careers
            </NavLink>

            <NavLink
              to="/careers/life-at-obelus"
              className="white-txt sitemap-txt"
              aria-label="Life at obelus sitemap page"
            >
              Life at Obelus
            </NavLink>
            <NavLink
              to="/careers/internship"
              className="white-txt sitemap-txt"
              aria-label="Intership sitemap page"
            >
              Internship
            </NavLink>
            <NavLink
              to="/careers/open-positions"
              className="white-txt sitemap-txt"
              aria-label="Open positions sitemap page"
            >
              Open positions
            </NavLink>
          </div>
        </div>

        <div className="flex justify-center items-center flex-col sitemap-block">
          <p
            className="green-color-txt text-2xl contact-sitemap green-text-selection"
            style={{ marginLeft: -20 }}
          >
            CONTACT
          </p>
          <div className="flex justify-center items-start flex-col mt-5">
            <div className="flex justify-start items-start flex-col">
              <div className="flex justify-center items-center">
                <GrMail color="#1cdb86" size={25} />
                <p className="white-txt ml-2">Mail:</p>
              </div>
              <p className="white-txt">
                <a href="mailto:info@obelus.rs" aria-label="Obelus doo Email">
                  info@obelus.rs
                </a>
              </p>
            </div>

            <div className="flex justify-start items-start flex-col mt-7 sitemap-contact-spacing">
              <div className="flex justify-center items-center">
                <BsTelephoneFill color="#1cdb86" size={25} />
                <p className="white-txt ml-2">Phone:</p>
              </div>
              <p className="white-txt">
                <a href="tel:+381677500005" aria-label="Obelus doo Phone">
                  +381 677 500005
                </a>
              </p>
            </div>

            <div className="flex justify-start items-start flex-col mt-7 sitemap-contact-spacing">
              <div className="flex justify-center items-center">
                <MdLocationPin color="#1cdb86" size={25} />
                <p className="white-txt ml-2">Address:</p>
              </div>
              <a
                href="https://goo.gl/maps/Wih8RPxVrQy1MtSz5"
                target="_blank"
                rel="noreferrer"
                aria-label="Obelus doo address"
              >
                <p className="white-txt">Vardarska 15</p>
                <p className="white-txt">18000 Niš, Serbia</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="shaped-div"></div>
      <footer className="footer flex justify-center items-center">
        <p className="white-txt italic text-lg copyright">
          Copyright &copy; {new Date().getFullYear()} Obelus. All Rights
          Reserved
        </p>
      </footer>
    </div>
  );
};

export default SiteMap;
