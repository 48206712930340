import { TbDeviceMobile } from "react-icons/tb";
import { BsGlobe } from "react-icons/bs";
import { MdOutlineCasino } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { IoNewspaperOutline, IoPeopleOutline } from "react-icons/io5";
import { VscComment } from "react-icons/vsc";

import Angular from "../assets/images/technologies/obelus-doo-angular.png";
import React from "../assets/images/technologies/obelus-doo-react.png";
import DotNet from "../assets/images/technologies/obelus-doo-dotnet-core.png";
import DotNetStandard from "../assets/images/technologies/obelus-doo-dotnet-standard.png";
import Node from "../assets/images/technologies/obelus-doo-nodejs.png";
import Flutter from "../assets/images/technologies/obelus-doo-flutter.png";
import SQL from "../assets/images/technologies/obelus-doo-sql.png";

import TMS from "../assets/images/CaseStudies/obelus-doo-traffic-monitoring-system.png";
import Booking from "../assets/images/CaseStudies/obelus-doo-booking-app.jpg";
import PaymentStations from "../assets/images/CaseStudies/obelus-doo-standalone-payment-stations.png";
import Sportsbook from "../assets/images/CaseStudies/obelus-doo-sportsbook.png";
import EBike from "../assets/images/CaseStudies/obelus-doo-rent-or-buy-ebike.jpg";
import TAS from "../assets/images/CaseStudies/obelus-doo-time-and-attendace-system.jpg";
import Transmondo from "../assets/images/CaseStudies/obelus-doo-qualified-audio-and-video-interpreting.png";
import Casino from "../assets/images/CaseStudies/obelus-doo-casino-games.png";
import Travel from "../assets/images/CaseStudies/obelus-doo-social-functionalities-for-travelers.png";

export const ServicesMapping = [
  {
    name: "Mobile App Development",
    icon: <TbDeviceMobile className="service-icon" />,
    description: "Our team of experienced developers and designers specialize in creating high-quality mobile apps that provide a seamless user experience. Whether you need a standalone app or a companion app to support your existing software, we have the expertise to bring your vision to life.",
  },
  {
    name: "Web App Development",
    icon: <BsGlobe className="service-icon" />,
    description: "We understand that every business has unique requirements and goals. That's why we offer custom web app development services that are tailored to meet the specific needs of your business. Our team of developers and designers will work with you to create a solution that fits your needs and enhances your online presence.",
  },
  {
    name: "Casino Games Development",
    icon: <MdOutlineCasino className="service-icon" />,
    description: "Our team of game developers have extensive experience creating engaging and interactive casino games. From slot machines to table games, we can create custom games that deliver a top-notch player experience.",
  },
  {
    name: "Dedicated Development Team",
    icon: <IoPeopleOutline className="service-icon" />,
    description: "We understand that every client's needs are unique, and that's why we offer dedicated development teams to ensure that your projects are delivered with the highest level of quality and efficiency. Our dedicated development teams are comprised of highly skilled software developers, project managers, and designers who are dedicated to delivering your project on time and within budget.",
  },
  {
    name: "Maintenance and Support",
    icon: <BiSupport className="service-icon" />,
    description: "After the development phase is complete, we offer ongoing maintenance and support services to ensure that your software continues to operate smoothly. Our team of developers and support specialists are available to provide assistance and resolve any issues that may arise.",
  },
  {
    name: "Technology Consulting",
    icon: <VscComment className="service-icon" />,
    description: "Our team of experts can provide guidance and support to help you make informed decisions about the technology that best suits your needs. From selecting the right platform to choosing the right development tools, we are here to help.",
  },
  {
    name: "Research and Consultancy",
    icon: <BsSearch className="service-icon" />,
    description: "With the rapid pace of technological change, it is becoming increasingly important to stay ahead of the curve and stay competitive in the market. This is where outsourcing your Research and Consultancy services can help you achieve your business objectives.",
  },
  {
    name: "Quality Assurance Services",
    icon: <IoNewspaperOutline className="service-icon" />,
    description: "We offer comprehensive quality assurance services, ensuring that your software is tested thoroughly and meets the highest standards of quality and reliability.",
  },
];

export const Frameworks = [
  {
    image: DotNet,
    title: ".NET Core",
  },
  {
    image: DotNetStandard,
    title: ".NET",
  },
  {
    image: SQL,
    title: "SQL",
  },
  {
    image: Angular,
    title: "Angular",
  },
  {
    image: React,
    title: "React",
  },
  {
    image: Node,
    title: "Node.js",
  },
  {
    image: Flutter,
    title: "Flutter",
  },
  {
    image: React,
    title: "React-Native",
  },
];

export const CaseStudiesProjects = [
  {
    title: "Traffic monitoring system",
    industry: "Traffic Industry",
    image: TMS,
    route: "/case-studies/traffic-monitoring-system",
    description: "A solution helps national parks to monitor and collect fees for using motor vehicles, improving revenue and enhancing the user experience."
  },
  {
    title: "Standalone payment stations",
    industry: "Finance Industry",
    image: PaymentStations,
    description: "A software for standalone payment stations, including those that accept both cash and credit card payments.",
    route: "/case-studies/standalone-payment-stations"
  },
  {
    title: "Booking app",
    industry: "Traffic Industry",
    image: Booking,
    route: "/case-studies/booking-app",
    description: "Online booking parking log is designed to streamline the parking process and make it easy and convenient for users to reserve and pay for parking spaces. "
  },
  {
    title: "Sportsbook System",
    industry: "Sports betting Industry",
    image: Sportsbook,
    description: "A comprehensive platform for sports betting that provides a seamless and secure experience for users.",
    route: "/case-studies/sportsbook-system"
  },
  {
    title: "Casino Games",
    image: Casino,
    industry: "Gaming industry",
    route: "/case-studies/casino-games",
    description:"Obelus has worked to develop solutions for the gaming industry, including online casinos. these solutions are designed to provide a seamless gaming experience and ensure the fairness and integrity of online gaming."
  },
  {
    title: "Rent or Buy E-Bike",
    industry: "Retail industry",
    image: EBike,
    description: "A platform that allows users to rent or purchase electric bikes for a fun and eco-friendly mode of transportation.",
    route: "/case-studies/rent-or-buy-e-bike",
  },
  {
    title: "Time and Attendance System",
    industry: "Human resources",
    image: TAS,
    description: "A solution that makes it easy for businesses to track employee time and attendance, calculate payroll, and monitor compliance with labour laws.",
    route: "/case-studies/time-and-attendance-system",
  },
  {
    title: "Qualified Video and Audio Interpreting",
    industry: "Translation industry",
    image: Transmondo,
    description: "A solution that enables organisations to access professional interpreters quickly and easily, 24/7, from anywhere in the world.",
    route: "/case-studies/qualified-video-and-audio-interpreting",
  },
  {
    title: "Social functionalities for travelers",
    industry: "Travel industry",
    description: "Social functionalities aimed at easier introduction and socialization of users traveling to the same destinations.",
    image: Travel,
    route: "/case-studies/social-functionalities-for-travelers",
  }
];