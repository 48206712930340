import React from "react";
import Header from "../components/Layout/Header/Header";
import Introduction from "../components/CaseStudies/Introduction/Introduction";
import ProjectsList from "../components/CaseStudies/Projects/ProjectsList";
import { useScroll } from "../hooks/useScroll";
import MetaTagasData from "../data/meta-tags";
import MetaTags from "../components/Layout/MetaTags";
import Newsletter from "../components/Layout/Newsletter/Newsletter";
import SiteMap from "../components/Layout/SiteMap/SiteMap";
import ScrollToTopButton from "../components/Layout/ScrollToTopButton";

const CaseStudies = () => {
  const metaData = MetaTagasData.CaseStudies;
  useScroll(metaData);

  return (
    <>
      <MetaTags data={metaData} />
      <Header isInverted={true} />
      <Introduction />
      <ProjectsList />
      <Newsletter />
      <SiteMap />
      <ScrollToTopButton />
    </>
  );
};

export default CaseStudies;
