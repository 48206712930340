import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useScroll = (metaData) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id !== undefined) {
      metaData.route += id;
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      } else {
        navigate(window.location.pathname.includes("careers") ? "/careers" : "/");
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [id, metaData, navigate]);
};
